'use strict';

module.exports =
/* @ngInject */
function ($sanitize, t) {

  this.handler = function (vm, defaultMsg) {
    return (err) => {
      console.error('error.handler', err);
      vm.error = $sanitize(t(defaultMsg) || t('error.generic'));
      // Use string as an error message
      if (typeof err === 'string') {
        vm.error = $sanitize(t(err));
        return null;
      }
      // Try to translate error code
      if (err && err.data && err.data.code) {
        const code = 'error.' + err.data.code;
        const sample = t(code);
        if (sample !== code) {
          vm.error = $sanitize(sample);
          return null;
        }
      }
      // Try to translate error message
      if (err && err.data && err.data.message) {
        vm.error = $sanitize(t(err.data.message));
        return null;
      }
      return null;
    };
  };

}
