'use strict';

module.exports =
/* @ngInject */
function (t, Entity) {

  class Campus extends Entity {

    getName() {
      return this.name;
    }

    getAbbr() {
      return this.abbr;
    }

    hasFaculty(id) {
      if (this.faculties.length === 0) {
        return true;
      }
      return this.faculties.includes(id);
    }

    // Returns an array of allowed study cycles
    getStudyCycleMaskBits() {
      if (typeof this.studyCycleMask === 'undefined') {
        return [true, true, true];
      }
      const bits = Array(3);
      const mask = this.studyCycleMask;
      for (let i = 0; i < 3; i++) {
        bits[i] = !!((mask >> i) & 1);
      }
      return bits;
    }

    setStudyCycleMaskBits(bits) {
      this.studyCycleMask = bits.reduce((acc, x, i) => x << i | acc, 0);
      return this;
    }

    hasStudyCycleMask() {
      return typeof this.studyCycleMask !== 'undefined'
        && this.studyCycleMask < 7;
    }

  }

  return Campus;

};
