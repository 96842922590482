'use strict';

module.exports =
/* @ngInject */
function ($httpApi, $q, Faculty) {

  let promise = null;
  const instances = {};

  this.load = function () {
    if (promise) {
      return promise;
    }
    promise = $httpApi.get('/faculty')
      .then((res) => {
        return _.map(res.data, (item) => {
          const instance = new Faculty(item);
          instances[instance.id] = instance;
          return instance;
        });
      });
    return promise;
  };

  this.getAll = function () {
    return _.values(instances);
  };

  this.getInstance = function (id) {
    return instances[id];
  };

}
