'use strict';

const AuthService = require('./Auth.js');

module.exports =
/* @ngInject */
function () {

  this.$get = ['$injector', ($injector) => $injector.instantiate(AuthService)];
  this.authenticate = (scope) => ['auth', (auth) => auth.authenticate(scope)];

}
