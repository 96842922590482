'use strict';

module.exports =
/* @ngInject */
function ($state, $stateParams, auth, error, t) {

  const vm = this;

  auth.confirmEmail($stateParams.code)
    .then(() => {
      vm.isConfirmed = true;
    })
    .catch(error.handler(vm, t('auth.confirm.error_message')));

}
