'use strict';

const $ = require('jquery');

module.exports =
/* @ngInject */
function () {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const $container = $(element);
      const $label = $container.find('label');
      const $input = $container.find('input');
      const type = $input.attr('type');

      $input.addClass('hidden');
      $label.click(() => {
        $input.click();
        if (type === 'radio') {
          // ng-model changes only when event is fired 2 times
          $input.click();
        }
      });
    },
  };
}
