'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, settings) {

  const vm = this;

  vm.settings = settings.getAll();

  vm.change = function (key) {
    settings.set(key, vm.settings[key]);
  };

}
