export function readFileAsText(file) {
  const reader = new FileReader();
  reader.readAsText(file, 'UTF-8');
  return new Promise((resolve, reject) => {
    reader.addEventListener('load', (e) => resolve(e.target.result));
    reader.addEventListener('load', (e) => reject(e));
  });
}

export function formatAsDate(date) {
  if (!date) {
    return null;
  }
  const str = date instanceof Date
    ? date.toISOString()
    : date;
  return str.split('T')[0];
}

export function bindMethods(obj, methods) {
  for (let method of methods) {
    obj[method] = obj[method].bind(obj);
  }
}

export function serveTextAsFile(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
