'use strict';

const d3 = require('d3');
const _ = require('lodash');
const $ = require('jquery');

require('./UiBarChart.scss');

module.exports =
/* @ngInject */
function () {
  return {
    restrict: 'E',
    scope: {
      data: '<',
    },
    link: (scope, element, attrs) => {
      // NOTE: http://bl.ocks.org/mbostock/3885304

      const $element = $(element);
      const margin = { top: 10, right: 20, bottom: 20, left: 30 };

      const width = $element.width() - margin.left - margin.right;
      const height = $element.height() - margin.top - margin.bottom;

      const data = scope.data;

      const x = d3.scale.ordinal().rangeRoundBands([0, width], .1);
      const y = d3.scale.linear().range([height, 0]);

      const xAxis = d3.svg.axis().scale(x).orient('bottom');
      const yAxis = d3.svg.axis().scale(y).orient('left').ticks(5, 'd');

      const $tooltip = $('<div>').addClass('tooltip').hide();
      $element.append($tooltip);

      const svg = d3.select(element[0]).append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      let rendered = false;

      scope.$watch('data', (data) => {
        if (!_.isArray(data)) {
          return null;
        }
        if (rendered) {
          return null;
        }

        rendered = true;

        x.domain(_.map(data, (d) => d.name));
        y.domain([0, d3.max(data, (d) => d.value)]);

        svg.append('g')
          .attr('class', 'x axis')
          .attr('transform', 'translate(0,' + height + ')')
          .call(xAxis);

        d3.selectAll('.axis text').each(function (name) {
          const tick = d3.select(this);
          const item = _.find(data, { name });
          if (item.tooltip) {
            tick.append('title').text(item.tooltip);
          }
        });

        svg.append('g')
          .attr('class', 'y axis')
          .call(yAxis)
          .append('text')
          .attr('transform', 'rotate(-90)')
          .attr('y', 6)
          .attr('dy', '.71em')
          .style('text-anchor', 'end');

        svg.selectAll('.bar')
          .data(data)
          .enter().append('rect')
          .attr('class', 'bar')
          .attr('x', (d) => x(d.name))
          .attr('width', x.rangeBand())
          .attr('y', (d) => y(d.value))
          .attr('height', (d) => height - y(d.value))
          .on('mouseenter', function (d) {
            const bbox = this.getBBox();
            $tooltip
              .text(d.tooltip + ': ' + d.value)
              .css('left', margin.left + bbox.x - ($tooltip.innerWidth() - bbox.width) / 2)
              .css('top', margin.top + bbox.y)
              .show();
          })
          .on('mouseout', () => $tooltip.hide());
      });
    },
  };
}
