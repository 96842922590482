'use strict';

module.exports =
/* @ngInject */
function ($rootScope, auth, i18n) {

  const vm = this;

  vm.user = null;
  vm.hasAdminToken = !!auth.getAdminToken();

  // Add date object to root scope
  $rootScope.date = Date.now();

  // Get logged-in user right from startup.
  // Promise doesn't resolve if not logged in.
  auth.getUser().then((user) => {
    vm.user = user;
  });

  // Listen for events
  auth.on('login', (user) => {
    vm.user = user;
  });

  auth.on('logout', () => {
    vm.user = null;
  });

  vm.logout = () => auth.logout();
  vm.reloadWithAdminToken = () => auth.reloadWithAdminToken();
  vm.getLang = () => i18n.getLang();
  vm.setLang = (lang) => i18n.setLang(lang);

};
