'use strict';

module.exports =
/* @ngInject */
function ($httpApi, $q) {

  let loadPromise = null;
  let cache = null;

  this.load = function () {
    if (loadPromise) {
      return loadPromise;
    }
    loadPromise = $httpApi.get('/settings')
      .then((res) => {
        cache = res.data;
        return this;
      });
    return loadPromise;
  };

  this.getAll = function () {
    return cache;
  };

  this.get = function (key) {
    return cache && cache[key];
  };

  this.set = function (key, value) {
    cache[key] = value;
    $httpApi.put('/settings/' + key, { value });
  };

}
