'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($scope, $stateParams, applicationService) {

  const vm = this;

  const init = async () => {
    const appl = await applicationService.get($stateParams.id);
    vm.application = appl;
    vm.files = appl.getFiles();
  };

  init();

  vm.deleteFile = async file => {
    const reason = window.prompt('Provide a reason for deleting the file. '
      + 'Press cancel to abort deletion of this file.');
    const confirmed = reason !== null;
    if (confirmed) {
      console.log('Deleting a file with a reason:', reason);
      await applicationService.deleteFile(vm.application, file.id, reason);
      applicationService.clearInstances();
      await init();
      $scope.$apply();
    }
  };

}
