'use strict';

const $ = require('jquery');

module.exports =
/* @ngInject */
function () {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const $element = $(element);
      $element.dropdown({
        action: 'hide',
        direction: 'downward',
      });
      console.log($element);
      // Don't open the dropdown if we want to scroll the page
      // with finger
      setTimeout(() => $element.off('touchstart'));
    },
  };
}
