'use strict';

const _ = require('lodash');
const $ = require('jquery');

module.exports =
/* @ngInject */
function (error) {
  return {
    restrict: 'E',
    template: require('./UiForm.html'),
    scope: true,
    transclude: true,
    link: (scope, element, attrs, ctrl, transclude) => {
      const $element = $(element);
      const $label = $element.find('label');
      const $input = $element.find('input');

      transclude(scope, (clone, scope) => {
        $element.find('form').append(clone);
      });
      if (attrs.bind) {
        scope.$eval(attrs.bind + ' = $form', scope);
      }

      // Generic error handler
      // Use in promises: .catch(vm.form.$errorHandler('error.foo'));
      scope.$form.$errorHandler = function (defaultMsg) {
        return error.handler(scope, defaultMsg);
      };

      // Set a field error
      scope.$form.$setFieldError = function (field, msg) {
        if (scope.$form[field]) {
          scope.$form[field].$setDirty(true);
          scope.$form[field].$setValidity('custom', false);
          scope.$form[field].$error.custom = msg;
        } else {
          scope.error = msg;
        }
      };

      scope.$submit = function () {
        if (scope.$form.$invalid) {
          _.each(scope.$form, (item, i) => {
            if (i.substring(0, 1) !== '$') {
              item.$setDirty(true);
            }
          });
          return;
        }
        if (attrs.submit) {
          scope.$eval(attrs.submit, scope);
        }
      }
    },
  };
}
