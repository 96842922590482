'use strict';

module.exports =
/* @ngInject */
function ($httpApi, $q, $state, User) {

  // Create an event emitter
  const emitter = require('event-emitter')();

  // Publish event emitter 'on' function to service
  this.on = (name, fn) => emitter.on(name, fn);

  this.userPromise = null;

  this.login = function (username, password) {
    return $httpApi.post('/auth/login', { username, password })
      .then((res) => {
        const token = res.data.token;
        this.setToken(token);
        this.reloadUser();
        return this.getUser();
      })
      .then((user) => {
        emitter.emit('login', user);
        return user;
      });
  };

  this.loginWithToken = function (token) {
    return $httpApi.post('/auth/login', { token })
      .then((res) => {
        const token = res.data.token;
        this.setToken(token);
        this.reloadUser();
        return this.getUser();
      })
      .then((user) => {
        emitter.emit('login', user);
        return user;
      });
  };

  this.logout = function () {
    this.setToken();
    this.setAdminToken();
    this.userPromise = null;
    emitter.emit('logout');
    return $q.when();
  };

  this.check = function (username) {
    return $httpApi
      .get('/auth/check', {
        params: { username },
      })
      .then((res) => res.data);
  };

  this.sendPasswordResetToken = function (email) {
    return $httpApi.post('/auth/password/reset/send_token', { email })
      .then((res) => res.data);
  };

  this.checkPasswordResetToken = function (code) {
    return $httpApi.post('/auth/password/reset/check_token', { code })
      .then((res) => res.data);
  };

  this.sendLoginToken = function (email) {
    return $httpApi.post('/auth/login/send_token', { email })
      .then((res) => res.data);
  };

  this.sendApplicationInfo = function (email) {
    return $httpApi
      .post('/auth/login/send_token', { email }, {
        params: { type: 'application' },
      })
      .then((res) => res.data);
  };

  this.resetPassword = function (code, email, password) {
    return $httpApi
      .post('/auth/password/reset', { code, email, password })
      .then((res) => res.data);
  };

  this.changePassword = function (oldPassword, newPassword) {
    return $httpApi
      .post('/auth/password/change', { oldPassword, newPassword })
      .then((res) => res.data);
  };

  this.reloadUser = function () {
    // Save promise to a user
    this.userPromise = $q
      .all([
        User.ensureServices(),
        $httpApi.get('/auth/user'),
      ])
      .then(([_, res]) => {
        // Update token with one inside the user data
        if (res.data.token) {
          this.setToken(res.data.token);
        }
        // Create user entity
        const user = new User(res.data);
        // Save the admin token separately
        if (user.isAdministrator()) {
          this.setAdminToken(res.data.token);
        }
        // Return a User entity
        return user;
      })
      .catch((err) => {
        this.logout();
        return $q.reject();
      });
    return this.userPromise;
  }

  this.getUser = function () {
    if (!this.getToken()) {
      return $q.reject(null);
    }
    if (this.userPromise !== null) {
      return $q.when(this.userPromise);
    }
    return this.reloadUser();
  };

  this.updateUser = function (userData) {
    return this.getUser()
      .then((user) => {
        return $httpApi.put('/auth/user', userData);
      })
      .then((res) => {
        this.userPromise = $q.when(new User(res.data));
        return this.userPromise;
      });
  };

  this.authenticate = function (predicateFn) {
    return this.getUser()
      .then((user) => {
        if (typeof predicateFn === 'function' && !predicateFn(user)) {
          return $q.reject('error.auth.not_authorized');
        }
        return user;
      })
      .catch((err) => {
        // NOTE: See config sections for '$state.next'
        if ($state.current.name === 'auth') {
          $state.go('auth', { to: $state.next.name }, { location: 'replace' });
        } else {
          $state.go('auth', { to: $state.next.name });
        }
        return $q.reject(err);
      });
  };

  this.authorize = this.authenticate;

  let _token = null;

  this.getToken = function () {
    // Workaround missing localStorage
    if (!localStorage) {
      return _token;
    }
    return localStorage.getItem('auth_token');
  };

  this.setToken = function (token) {
    // Workaround missing localStorage
    if (!localStorage) {
      _token = token || null;
      emitter.emit('token', token);
      return this;
    }
    if (token) {
      localStorage.setItem('auth_token', token);
    } else {
      localStorage.removeItem('auth_token');
    }
    emitter.emit('token', token);
    return this;
  };

  this.getAdminToken = function () {
    return localStorage.getItem('auth_admin_token');
  };

  this.setAdminToken = function (token) {
    if (token) {
      localStorage.setItem('auth_admin_token', token);
    } else {
      localStorage.removeItem('auth_admin_token');
    }
    emitter.emit('admin_token', token);
    return this;
  };

  this.reloadWithAdminToken = function () {
    this.setToken(this.getAdminToken());
    location.reload();
  };

}
