'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, $stateParams, t, User, userService, campusService) {

  const vm = this;

  vm.roles = {
    administrator: t('user.role.administrator'),
    superintendent: t('user.role.superintendent'),
  };

  campusService.load().then((items) => {
    vm.campuses = items;
  });

  userService.find({ administrative: true }).then((items) => {
    vm.users = items;
    if ($state.is('admin_settings_users.edit')) {
      vm.user = _.find(items, { id: $stateParams.id });
      vm.setupForm();
    }
  });

  vm.setupForm = function () {
    // Setup campus selection
    vm.campusSelection = {};
    if (vm.user.scope) {
      vm.user.scope.campus.forEach((x) => {
        vm.campusSelection[x.id] = true;
      });
    }
  };

  vm.flushForm = function () {
    // Read campus selection
    vm.user.scope.campus = [];
    _.each(vm.campusSelection, (value, i) => {
      if (value) {
        vm.user.scope.campus.push(i);
      }
    });
  };

  vm.create = function () {
    vm.user = new User();
    vm.setupForm();
    $state.transitionTo('admin_settings_users.create');
  };

  vm.createSubmit = function (user) {
    vm.flushForm();
    if (user.password !== user.password_confirmation) {
      vm.form.$setFieldError('password_confirmation', 'error.user.passwords_do_not_match');
      return null;
    }
    userService.create(user)
      .then(() => {
        $state.go('admin_settings_users.index', {}, { reload: true });
      })
      .catch(vm.form.$errorHandler());
  };

  vm.edit = function (user) {
    vm.user = user;
    vm.setupForm();
    $state.transitionTo('admin_settings_users.edit', { id: user.id });
  };

  vm.editSubmit = function (user) {
    vm.flushForm();
    if (!user.password && !user.password_confirmation) {
      delete user.password;
      delete user.password_confirmation;
    } else {
      if (user.password !== user.password_confirmation) {
        vm.form.$setFieldError('password_confirmation', 'error.user.passwords_do_not_match');
        return null;
      }
    }
    userService.update(user)
      .then(() => {
        $state.go('admin_settings_users.index', {}, { reload: true });
      })
      .catch(vm.form.$errorHandler());
  };

  vm.remove = function (user) {
    const confirmed = window.confirm('user.delete_dialog');
    if (!confirmed) {
      return null;
    }
    userService.remove(user).then(() => {
      $state.reload();
    });
  }

}
