'use strict';

const _ = require('lodash');

require('./UiFormDropdown.scss');

function boolAttr(vm, attrs, id) {
  if (typeof attrs[id] === 'string' && attrs[id].length === 0) {
    vm[id] = true;
  }
}

module.exports = {
  template: require('./UiFormDropdown.html'),
  bindings: {
    model: '=',
    options: '<',
    optionValue: '&',
    optionText: '&',
    optionModel: '&',
    optionDisabled: '&',
    modelDefault: '<?',
    disabled: '<?',
    required: '<?',
    label: '@',
    name: '@',
  },
  /* @ngInject */
  controller: function ($scope, $element, $attrs, $parse) {
    const $ctrl = this;
    const $form = $scope.$parent.$form;

    // Convert attributes without values to 'true'
    boolAttr($ctrl, $attrs, 'disabled');
    boolAttr($ctrl, $attrs, 'required');
    // Initialize defaults
    if ($attrs.modelType === 'entity') {
      if (!$attrs.optionValue) {
        $ctrl.optionValue = $parse('$value.id');
      }
      if (!$attrs.optionText) {
        $ctrl.optionText = $parse('$value.name');
      }
      if (!$attrs.optionModel) {
        $ctrl.optionModel = $parse('$value');
      }
    } else {
      if (!$attrs.optionValue) {
        $ctrl.optionValue = $parse('$key');
      }
      if (!$attrs.optionText) {
        $ctrl.optionText = $parse('$value');
      }
      if (!$attrs.optionModel) {
        $ctrl.optionModel = $parse('$key');
      }
    }
    if (!$attrs.optionDisabled) {
      $ctrl.optionDisabled = $parse('false');
    }

    $ctrl.$postLink = function () {
      const $dropdown = $element.find('.ui.dropdown');
      const $text = $element.find('.text');
      // Add top level classes
      $element.addClass('field');
      if ($ctrl.required) {
        $element.addClass('required');
      }
      // Assign form object to controller for error handling
      if ($ctrl.name && $form && $form[$ctrl.name]) {
        $ctrl.form = $form[$ctrl.name];
      }
      // Initialize dropdown
      $dropdown.dropdown({
        direction: 'downward',
        onShow: () => $element.addClass('active'),
        onHide: () => $element.removeClass('active'),
        onChange: (value) => {
          _.find($ctrl.options, ($value, $key) => {
            const result = $ctrl.optionValue({ $value, $key });
            if (_.isEqual(result, value)) {
              $ctrl.model = $ctrl.optionModel({ $value, $key });
              return true;
            }
            return false;
          });
          $ctrl.onChange();
          $scope.$applyAsync();
        },
      });
      // Don't open the dropdown if we want to scroll the page with a finger
      setTimeout(() => $dropdown.off('touchstart'));
      // Start watching model changes
      $scope.$watch('$ctrl.model', (model) => {
        _.find($ctrl.options, ($value, $key) => {
          const result = $ctrl.optionModel({ $value, $key });
          const equal = _.isEqualWith(result, model, (a, b) => {
            if (a == b) {
              return true;
            }
          });
          if (equal) {
            const text = $ctrl.optionText({ $value, $key });
            $text.text(text).removeClass('default');
            return true;
          }
          return false;
        });
      });
      // Watch for modelDefault
      // TODO: Rework this to $ctrl.$onChanges
      const unwatchModelDefault =
      $scope.$watch('$ctrl.modelDefault', () => {
        if ($ctrl.modelDefault && $ctrl.model === undefined) {
          $ctrl.model = $ctrl.modelDefault;
        }
        if ($ctrl.model !== undefined) {
          unwatchModelDefault();
        }
      });
    };

    $ctrl.onChange = function () {
      if ($ctrl.form) {
        $ctrl.form.$setValidity('custom', true);
      }
    };
  },
};
