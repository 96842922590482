'use strict';

const angular = require('angular');

// Define a module
const app = angular.module('vub-is.accom.admin', [
  require('../core'),
  require('./dashboard'),
  require('./settings'),
]);

// Setup the states
app.config(($stateProvider, authProvider) => {
  $stateProvider.state('admin_index', {
    url: '/a/',
    onEnter: ($state) => $state.go('admin_dashboard.index'),
  });
});

module.exports = app.name;
