'use strict';

module.exports =
/* @ngInject */
function ($q, t, Entity, Campus, User, campusService, facultyService) {

  const EDITABLE_STATUSES = ['pending', 'queued'];
  const CANCELLABLE_STATUSES = ['unconfirmed', 'pending', 'queued', 'invited', 'accepted'];
  const TERMINAL_STATUSES = ['completed', 'void', 'expired', 'cancelled'];

  class Application extends Entity {

    // Make sure all services are loaded before using this entity
    static ensureServices() {
      return $q.all([
        campusService.load(),
        facultyService.load(),
      ]);
    }

    constructor(data) {
      super(data);
      if (this.user) {
        this.user = new User(this.user);
      }
      if (this.campus) {
        this.campus = campusService.getInstance(this.campus);
      }
      if (this.faculty) {
        this.faculty = facultyService.getInstance(this.faculty);
      }
    }

    toJSON() {
      const obj = super.toJSON();
      if (typeof obj.campus === "object") {
        obj.campus = obj.campus.id;
      }
      if (typeof obj.faculty === "object") {
        obj.faculty = obj.faculty.id;
      }
      return obj;
    }

    getFullName() {
      // If name is undefined, it's probably an admin account.
      if (!this.firstName || !this.lastName) {
        return this.email;
      }
      return this.firstName + ' ' + this.lastName;
    }

    getFiles() {
      return this.files.map((file) => ({
        id: file,
        name: file.split('/').pop(),
        url: '/uploads/' + file,
        ...(this.filesMeta && this.filesMeta[file]),
      }));
    }

    getLocalStatus() {
      return t('application.status.' + this.status);
    }

    getLocalAdminStatus() {
      if (this.type === 'freshmen') {
        if (this.status === 'pending') {
          return 'registruotas';
        }
      }
      return t('admin.application.status.' + this.status);
    }

    getLocalUserAreaStatus() {
      return t('userarea.application.status.' + this.status);
    }

    getLocalType() {
      return t('application.type.' + this.type);
    }

    getLocalRoomType() {
      return t('room_type.' + this.roomType);
    }

    isPending() {
      return this.status === 'pending';
    }

    isUnconfirmed() {
      return this.status === 'unconfirmed';
    }

    isQueued() {
      return this.status === 'queued';
    }

    isInvited() {
      return this.status === 'invited';
    }

    isAccepted() {
      return this.status === 'accepted';
    }

    isCompleted() {
      return this.status === 'completed';
    }

    hasTerminalStatus() {
      return TERMINAL_STATUSES.includes(this.status);
    }

    isEditable() {
      return EDITABLE_STATUSES.includes(this.status);
    }

    isCancellable() {
      return CANCELLABLE_STATUSES.includes(this.status);
    }

  }

  return Application;

};
