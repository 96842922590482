'use strict';

const $ = require('jquery');

module.exports =
/* @ngInject */
function () {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      $(element).popup({
        inline: true,
        position: attrs.uiPopupPosition || 'top left',
        on: attrs.uiPopup || 'hover',
      });
    },
  };
}
