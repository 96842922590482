'use strict';

module.exports =
/* @ngInject */
function LoginTokenController($state, $stateParams, auth, error, t) {

  const vm = this;

  auth.loginWithToken($stateParams.code)
    .then((user) => {
      if (user.isAdministrativeUser()) {
        $state.go('admin_dashboard.index', {}, { location: 'replace' });
      } else {
        $state.go('application.list', {}, { location: 'replace' });
      }
    })
    .catch(error.handler(vm, t('auth.login.error_message')));

}
