'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, $scope, $q, user, applicationService, facultyService,
    campusService, t, error) {

  const vm = this;

  //  Static information
  // ----------------------------------
  vm.user = user;

  vm.sexes = {
    'male': t('sex.male'),
    'female': t('sex.female'),
  };

  vm.roomTypes = _.map(applicationService.roomTypes, (name, id) => {
    return { id, name: t(name) };
  });

  // Load all remote data first
  // then assign watchers and start updating the view
  $q
    .all([
      facultyService.load().then((faculties) => {
        vm.faculties = _.clone(faculties);
        vm.faculties.unshift({
          id: void 0,
          name: t('any'),
        });
      }),
      campusService.load().then((campuses) => {
        vm.campuses = campuses;
      }),
    ])
    .then(() => {
      $scope.$watchCollection('vm.criteria', (value) => {
        if (user.isAdministrator()) {
          // Trick: wait until campus filter has initialized
          if (vm.criteria.campus) {
            vm.updateView();
          }
        } else {
          vm.updateView();
        }
      });
    });


  //  Criteria building
  // ----------------------------------

  vm.criteria = {
    sexes: {},
    roomTypes: {},
    faculties: {},
  };

  vm.getCount = function (type, id) {
    const queue = vm.queue;
    if (!queue) {
      // Disable until we have stats loaded
      return 0;
    }
    if (!_.isObject(queue[type])) {
      return 0;
    }
    return vm.queue[type][id] || 0;
  };

  vm.isDisabled = function (type, id) {
    const disabled = vm.getCount(type, id) === 0;
    if (disabled) {
      vm.criteria[type][id] = false;
    }
    return disabled;
  };

  function buildCriteria() {
    const criteria = {};
    criteria.campus = vm.criteria.campus;
    const sex = _(vm.criteria.sexes).omitBy(x => !x).keys().value();
    if (sex.length > 0) {
      criteria.sex = sex;
    }
    const roomType = _(vm.criteria.roomTypes).omitBy(x => !x).keys().value();
    if (roomType.length > 0) {
      criteria.roomType = roomType;
    }
    const faculty = _(vm.criteria.faculties).omitBy(x => !x).keys().value();
    if (faculty.length > 0) {
      criteria.faculty = faculty;
    }
    return criteria;
  }


  //  Actions
  // ----------------------------------

  vm.updateView = function () {
    const criteria = buildCriteria();
    applicationService.getQueueStatistics(criteria)
      .then((queue) => {
        vm.queue = queue;
      });
  };

  vm.submit = function (item) {
    const confirmed = window.confirm(t('admin.dashboard.invite_dialog'));
    if (!confirmed) {
      return null;
    }
    const criteria = buildCriteria();
    applicationService.invite(criteria)
      .then(() => $state.go('admin_dashboard.queue'))
      .catch(error.handler(vm));
  };



}
