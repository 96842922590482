'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, $stateParams, user, t, campusService, facultyService) {

  const vm = this;

  vm.user = user;

  campusService.load().then((items) => {
    vm.campuses = items;
    if ($state.is('admin_settings_campuses.edit')) {
      vm.campus = _.find(items, { id: $stateParams.id });
      vm.setupForm();
    }
  });

  facultyService.load().then((items) => {
    vm.faculties = items;
  });

  vm.setupForm = function () {
    // Setup faculty selection
    vm.facultySelection = {};
    vm.campus.faculties.forEach((x) => {
      vm.facultySelection[x] = true;
    });
    // Setup study cycle mask bits
    vm.studyCycleMaskBits = vm.campus.getStudyCycleMaskBits();
  };

  vm.flushForm = function () {
    // Read faculty selection
    vm.campus.faculties = [];
    _.each(vm.facultySelection, (value, i) => {
      if (value) {
        vm.campus.faculties.push(i);
      }
    });
    // Read study cycle mask bits
    vm.campus.setStudyCycleMaskBits(vm.studyCycleMaskBits);
  };

  vm.edit = function (item) {
    vm.campus = item;
    vm.setupForm();
    $state.transitionTo('admin_settings_campuses.edit', { id: item.id });
  };

  vm.editSubmit = function (item) {
    vm.flushForm();
    campusService.update(item)
      .then(() => {
        $state.go('admin_settings_campuses.index', {}, { reload: true });
      })
      .catch(vm.form.$errorHandler());
  };

}
