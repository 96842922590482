import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';

export default function TinyButton(props) {
  return <Button {...props}
    style={{ margin: '-5px 2px', padding: '0.3em' }}
    as={props.as || (props.href ? 'a' : 'button')}
    compact icon>
    <Icon name={props.icon} />
  </Button>
}
