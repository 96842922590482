'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($q, t, Entity, Campus, Faculty, facultyService, campusService) {

  class User extends Entity {

    // Make sure all services are loaded before using this entity
    static ensureServices() {
      return $q.all([
        facultyService.load(),
        campusService.load(),
      ]);
    }

    constructor(data) {
      super(data);
      if (this.faculty) {
        this.faculty = facultyService.getInstance(this.faculty);
      }
      if (this.scope && this.scope.campus) {
        this.scope.campus = this.scope.campus
          .map((x) => campusService.getInstance(x));
      }
    }

    toJSON() {
      const obj = super.toJSON();
      obj.scope = {};
      if (typeof this.faculty === "object") {
        obj.faculty = this.faculty.id;
      }
      if (this.role === 'superintendent') {
        if (this.scope && this.scope.campus) {
          obj.scope.campus = _.map(this.scope.campus, (item) => {
            if (typeof item === 'object') {
              return item.id;
            }
            return item;
          });
        }
      } else {
        obj.scope = {
          campus: [],
        };
      }
      return obj;
    }

    export() {
      return _.pick(this, [
        'firstName', 'lastName', 'email', 'phoneNumber',
        'personalCode', 'studentCode', 'sex',
        'country', 'city', 'address', 'faculty',
      ]);
    }

    getFullName() {
      // If name is undefined, it's probably an admin account.
      if (!this.firstName || !this.lastName) {
        return this.email;
      }
      return this.firstName + ' ' + this.lastName;
    }

    getScopeCampusNames() {
      if (!this.scope) {
        return '--';
      }
      return this.scope.campus
        .map((item) => item.abbr)
        .join(', ') || '--';
    }

    isAdministrator() {
      return this.role === 'administrator';
    }

    isSuperintendent() {
      return this.role === 'superintendent';
    }

    isAdministrativeUser() {
      return this.role === 'administrator' || this.role === 'superintendent';
    }

    isNormalUser() {
      return this.role === 'normal';
    }

    hasCampusInScope(id) {
      if (this.isAdministrator()) {
        return true;
      }
      return _.some(this.scope.campus, (x) => x.id === id);
    }

  }

  return User;

};
