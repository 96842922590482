'use strict';

// Create the global re-captcha initialization callback
const ready = new Promise((resolve, reject) => {
  window.onRecaptchaLoaded = resolve;
});

module.exports =
/* @ngInject */
function () {
  return {
    restrict: 'A',
    require: "ngModel",
    scope: {
      ngModel: '=',
    },
    link: (scope, element, attrs, ctrl) => {

      ctrl.$setValidity('recaptcha', false);

      function expired() {
        ctrl.$setViewValue(null);
        ctrl.$setValidity('recaptcha', false);
      }

      function done(res) {
        ctrl.$setViewValue(res);
        ctrl.$setValidity('recaptcha', !!res);
      }

      ready.then(() => {
        window.grecaptcha.render(element[0], {
          sitekey: attrs.sitekey,
          callback: done,
          'expired-callback': expired,
        });
      });

    },
  };
}
