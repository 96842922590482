'use strict';

const _ = require('lodash');

function boolAttr(vm, attrs, id) {
  if (typeof attrs[id] === 'string' && attrs[id].length === 0) {
    vm[id] = true;
  }
}

module.exports = {
  template: require('./UiFilterButton.html'),
  bindings: {
    model: '=',
    options: '<',
    optionValue: '&',
    optionText: '&',
    optionModel: '&',
    optionDisabled: '&',
    modelDefault: '<?',
    disabled: '<?',
  },
  /* @ngInject */
  controller: function ($scope, $element, $attrs, $parse) {
    const $ctrl = this;

    // Convert attributes without values to 'true'
    boolAttr($ctrl, $attrs, 'disabled');
    // Initialize defaults
    if ($attrs.modelType === 'entity') {
      if (!$attrs.optionValue) {
        $ctrl.optionValue = $parse('$value.id');
      }
      if (!$attrs.optionText) {
        $ctrl.optionText = $parse('$value.name');
      }
      if (!$attrs.optionModel) {
        $ctrl.optionModel = $parse('$value');
      }
    } else {
      if (!$attrs.optionValue) {
        $ctrl.optionValue = $parse('$key');
      }
      if (!$attrs.optionText) {
        $ctrl.optionText = $parse('$value');
      }
      if (!$attrs.optionModel) {
        $ctrl.optionModel = $parse('$key');
      }
    }
    if (!$attrs.optionDisabled) {
      $ctrl.optionDisabled = $parse('false');
    }

    $ctrl.$postLink = function () {
      const $dropdown = $element.find('.ui.dropdown');
      const $text = $element.find('.text');
      // Initialize dropdown
      $dropdown.dropdown({
        direction: 'downward',
        onShow: () => $element.addClass('active'),
        onHide: () => $element.removeClass('active'),
        onChange: (value) => {
          _.find($ctrl.options, ($value, $key) => {
            const result = $ctrl.optionValue({ $value, $key });
            if (_.isEqual(result, value)) {
              $ctrl.model = $ctrl.optionModel({ $value, $key });
              return true;
            }
            return false;
          });
          $scope.$applyAsync();
        },
      });
      // Don't open the dropdown if we want to scroll the page with a finger
      setTimeout(() => $dropdown.off('touchstart'));
      // Start watching model changes
      $scope.$watch('$ctrl.model', (model) => {
        _.find($ctrl.options, ($value, $key) => {
          const result = $ctrl.optionModel({ $value, $key });
          if (_.isEqual(result, model)) {
            const text = $ctrl.optionText({ $value, $key });
            $text.text(text).removeClass('default');
            return true;
          }
          return false;
        });
      });
      // Watch for modelDefault
      // TODO: Rework this to $ctrl.$onChanges
      const unwatchModelDefault =
      $scope.$watch('$ctrl.modelDefault', () => {
        if ($ctrl.modelDefault && $ctrl.model === undefined) {
          $ctrl.model = $ctrl.modelDefault;
        }
        if ($ctrl.model !== undefined) {
          unwatchModelDefault();
        }
      });
    };
  },
};
