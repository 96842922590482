'use strict';

const _ = require('lodash');

function boolAttr(vm, attrs, id) {
  if (typeof attrs[id] === 'string' && attrs[id].length === 0) {
    vm[id] = true;
  }
}

module.exports = {
  template: require('./UiFormCheckbox.html'),
  bindings: {
    model: '=',
    disabled: '<?',
    required: '<?',
    checked: '<?',
    label: '@',
    name: '@',
  },
  /* @ngInject */
  controller: function ($scope, $element, $attrs) {
    const $ctrl = this;
    const $form = $scope.$parent.$form;

    // Convert attributes without values to 'true'
    boolAttr($ctrl, $attrs, 'disabled');
    boolAttr($ctrl, $attrs, 'required');
    boolAttr($ctrl, $attrs, 'checked');

    $ctrl.$postLink = function () {
      const $label = $element.find('label');
      const $input = $element.find('input');
      // Add top level classes
      $element.addClass('field');
      if ($ctrl.required) {
        $element.addClass('required');
      }
      // Setup label clicking
      $input.addClass('hidden');
      $label.click(function (e) {
        if (this !== e.target) {
          return true;
        }
        $input.click();
      });
      // Assign form object to controller for error handling
      if ($ctrl.name && $form && $form[$ctrl.name]) {
        $ctrl.form = $form[$ctrl.name];
      }
      // NOTE: Fixes a bug when sometimes checkbox isn't checked
      setTimeout(() => $input.prop('checked', $ctrl.model));
    };

    $ctrl.onChange = function () {
      const $input = $element.find('input');
      $input.prop('checked', $ctrl.model);
      if ($ctrl.form) {
        $ctrl.form.$setValidity('custom', true);
      }
    };
  },
};
