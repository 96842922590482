module.exports    = {
	"any": "Any",
	"save": "Išsaugoti",
	"edit": "Redaguoti",
	"delete": "Ištrinti",
	"cancel": "Atšaukti",
	"next": "Toliau",
	"date": "Data",
	"time": "Laikas",
	"action": "Veiksmas",
	"actions": "Valdymas",
	"show": "Rodyti",
	"yes": "Taip",
	"no": "Ne",
	"submit": "Siųsti",
	"send": "Siųsti",
	"download": "Parsisiųsti",
	"return": "Grižti atgal",
	"more": "Daugiau",
	"write_mail": "Rašyti laiška",
	"open_map": "Atidaryti žemelapyje",
	"of": "iš",
	"search": "Ieškoti",
	"and": "ir",
	"or": "arba",
	"print_agreement": "Spausdinti sutartį",
	"reason_for_deletion": "Byla pašalinta, priežastis",
	"menu": {
		"title": "Pradžia",
		"home": "Pradžia",
		"support": "Pagalba",
		"back_to_admin": "Persijungti kaip administratorius",
		"logout": "Atsijungti",
		"change_password": "Pakeisti slaptažodį",
		"language": "Kalba",
		"dashboard": "Darbalaukis",
		"search": "Paieška",
		"settings": "Nustatymai",
		"settings_global": "Nustatymai",
		"manage_users": "Valdyti vartotojus",
		"manage_campuses": "Valdyti miestelius"
	},
	"validation": {
		"required": "Šis laukas privalomas!",
		"number": "Šiame lauke turi būti skaitmenys!"
	},
	"sex": {
		"male": "Vyras",
		"female": "Moteris"
	},
	"room_type": {
		"any": "Nesvarbu",
		"double": "Dvivietis",
		"double_1": "Visas dvivietis",
		"triple": "Trivietis",
		"triple_2": "Trivietis (2 žmonėms)",
		"triple_1": "Visas trivietis"
	},
	"settings": {
		"list": "Nustatymai",
		"freshmen_registration": "Registracija pirmakursiams",
		"queue_registration": "Registracija į bendrą eilę",
		"freshmen_cycle_1": "Leisti registruotis bakalaurams",
		"freshmen_cycle_2": "Leisti registruotis magistrams",
		"freshmen_cycle_3": "Leisti registruotis doktorantams ir rezidentams"
	},
	"application": {
		"item": "Prašymas",
		"list": "Prašymai",
		"cancel_dialog": "Ar tikrai norite atšaukti šį prašymą?",
		"item_description": "Registracijos informacija:",
		"unconfirmed_message": "Jūsų prašymas dar neaktyvuotas, nes jūs nepatvirtinote savo el. pašto adreso.",
		"queued_message": "Jūsų prašymas laukia eilėje.",
		"invited_message": "<p class=\"header\">Jūs esate pakviestas(-a) gyventi bendrabutyje.</p>\n<p>Patvirtinkite, kad jus vis dar norite apsigyventi pasirinktame bendrabutyje.</p>\n",
		"accepted_message": "Atvykite į jums paskirtą bendrabutį.",
		"field": {
			"campus": "Miestelis",
			"faculty": "Fakultetas",
			"sex": "Lytis",
			"study_cycle": "Studijų pakopa",
			"study_cycle_short": "SP",
			"room_type": "Kambario tipas",
			"status": "Statusas",
			"type": "Tipas",
			"queue_position": "Vieta eilėje",
			"accommodated": "Apgyvendintas",
			"accommodated_short": "Apgyv.",
			"accommodated_checkbox": "Šiuo metu jau gyvenu bendrabutyje",
			"disadvantaged": "Socialiai remtinas",
			"disadvantaged_short": "Soc.",
			"disadvantaged_checkbox": "Esu soc. remtinas(-a), iš daugiavaikės šeimos, arba pateiksiu kitus dokumentus",
			"handicapped": "Neįgalusis",
			"handicapped_short": "Neįg.",
			"handicapped_checkbox": "Turiu dėl negalios kylančių poreikių apgyvendinimui",
			"tos_checkbox": "Susipažinau ir sutinku su\n<a href=\"/registracijos-taisykles\" target=\"_blank\">taisyklėmis</a>\n",
			"study_cycle_checkbox_1": "Esu bakalauro studijų programos studentas",
			"study_cycle_checkbox_2": "Esu magistrantūros studijų programos studentas",
			"study_cycle_checkbox_3": "Esu bakalauro arba magistrantūros studijų programos studentas",
			"study_cycle_checkbox_4": "Esu doktorantūros arba rezidentūros studijų programos studentas",
			"study_cycle_checkbox_5": "Esu bakalauro, doktorantūros arba rezidentūros studijų programos studentas",
			"study_cycle_checkbox_6": "Esu magistrantūros, doktorantūros arba rezidentūros studijų programos studentas",
			"study_cycle_checkbox_7": "Esu bakalauro, magistrantūros, doktorantūros arba rezidentūros studijų programos studentas",
			"notes": "Papildoma informacija ar pageidavimai",
			"created_at": "Sukūrimo data",
			"queued_at": "Eilėje nuo",
			"invited_at": "Kvietimo data",
			"accepted_at": "Kvietimo patvirtinimo data",
			"updated_at": "Atnaujinimo data",
			"campus_info": "Kontaktai",
			"files": "Prisegti failai"
		},
		"status": {
			"unconfirmed": "nepatvirtinta",
			"pending": "laukiama",
			"queued": "eilėje",
			"cancelled": "atšaukta",
			"invited": "pakviestas",
			"accepted": "patvirtintas",
			"completed": "apgyvendintas",
			"expired": "atmesta",
			"void": "anuliuota"
		},
		"type": {
			"queue": "bendra eilė",
			"freshmen": "pirmakursis"
		},
		"section": {
			"type": "Registracijos tipas",
			"user_info": "Asmeninė informacija",
			"user_info_description": "Užsisakyti vietą bendrabutyje galite užpildę šią anketa.",
			"preferences": "Pageidajama vieta gyventi",
			"preferences_description": "Pasirinkite pageidajamą miestelį bei kambario tipą."
		}
	},
	"user": {
		"item": "Vartotojas",
		"list": "Vartotojai",
		"new": "Naujas vartotojas",
		"add": "Pridėti vartotoją",
		"edit": "Redaguoti vartotoją",
		"delete": "Ištrinti vartotoją",
		"delete_dialog": "Ar tikrai norite ištrintį vartotoją?",
		"field": {
			"email": "El. paštas",
			"login_name": "Prisijungimo vardas",
			"first_name": "Vardas",
			"last_name": "Pavardė",
			"full_name": "Vardas, pavardė",
			"personal_code": "Asmens kodas",
			"personal_code_short": "Asmens kodas",
			"student_code": "Studento pažymėjimo numeris",
			"student_code_short": "SP #",
			"faculty": "Fakultetas",
			"faculty_short": "Fak.",
			"phone_number": "Telefono numeris",
			"phone_number_short": "Tel.",
			"your_phone_number": "Jūsų telefono numeris",
			"country": "Šalis",
			"city": "Miestas",
			"address": "Adresas",
			"sex": "Lytis",
			"date_of_birth": "Gimimo data",
			"role": "Rolė",
			"scope": "Miestelis",
			"scope_campus": "Authorized campus",
			"password": "Slaptažodis",
			"old_password": "Senas slaptažodis",
			"new_password": "Naujas slaptažodis",
			"password_confirmation": "Patvirtinkite slaptažodį",
			"registration_address": "Jūsų registracijos adresas (Tėvų adresas)",
			"passport_number": "Paso ar ID Nr.",
			"passport_location": "Paso ar ID išdavimo vieta",
			"parent_name": "Vieno iš tėvų (globėjo) vardas",
			"parent_lastname": "Vieno iš tėvų (globėjo) pavardė",
			"parent_address": "Vieno iš tėvų (globėjo) kontaktinis adresas",
			"parent_phone": "Vieno iš tėvų (globėjo) kontaktinis tel. nr."
		},
		"role": {
			"administrator": "Administratorius",
			"superintendent": "Komendantas"
		},
		"section": {
			"info": "Asmeninė informacija",
			"password": "Slaptažodis",
			"permissions": "Teisės"
		}
	},
	"campus": {
		"item": "Miestelis",
		"list": "Miesteliai",
		"new": "Naujas miestelis",
		"add": "Pridėti miestelį",
		"edit": "Redaguoti miestelį",
		"delete": "Ištrinti miestelį",
		"delete_dialog": "Ar tikrai norite ištrintį miestelį?",
		"field": {
			"name": "Pavadinimas",
			"abbr": "Sutrumpinimas",
			"email": "El. paštas",
			"address": "Adresas",
			"map_url": "Nuoroda i Google Maps",
			"info": "Kontaktai",
			"room_type_enabled": "Leisti pasirinkti kambario tipą",
			"force_pc_validation": "Privalomai tikrinti asmens kodus VU duomenų bazėje",
			"campus": "Bendrabutis",
			"room_number": "Kambario Nr.",
			"room_price": "Mokestis už bendrabutį"
		}
	},
	"userarea": {
		"home": {
			"title": "VU bendrabučių eilė",
			"subtitle": "Užsiregistruokite į elektroninę eilę vietai Vilniaus universiteto bendrabučiuose gauti.",
			"registration_button": "Registracija VU studentams",
			"registration_freshmen_button": "Registracija būsimiems VU pirmakursiams",
			"check_button": "Patikrinkite eilės būseną",
			"section_left": "<h2>GMail vartotojų dėmesiui</h2>\n<p>Informacija apie Jūsų eilės būseną atsiunčiama per kelias minutes.\n  Jei savo dėžutėje nematote laiško, patikrinkite katalogą\n  <strong><a href=\"https://mail.google.com/mail/#spam\">SPAM</a></strong>.</p>\n<h2>Kyla klausimų?</h2>\n<a class=\"ui blue button\" href=\"u/support\">Pagalbos puslapis</a>\n",
			"section_right": "<h2>Prisijunkite prie mūsų Facebook'e</h2>\n<iframe src=\"//www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2Fvubendrabuciai&width=500&height=220&colorscheme=light&show_faces=true&border_color&stream=true&header=true&appId=165896603512417&adapt_container_width=true\" scrolling=\"no\" frameborder=\"0\" style=\"border: none; overflow: hidden; width: 100%; height: 220px\" allowtransparency=\"true\"></iframe>\n"
		},
		"application": {
			"item": "Registracijos forma",
			"list": "Jūsų registracijos",
			"title_queue": "Jau studijuojantiems VU studentams",
			"title_freshmen": "Būsimiems VU studentams",
			"title_agreement": "Sutarties pradinis pildymas",
			"title_agreement_extension": "Apgyvendinimo sutarties pratęsimas",
			"submit_button": "Registruotis į eilę",
			"submit_error": "Registracija negalima. Pamėginkite vėliau.",
			"submit_success": "Sėkmingai užsiregistravote!",
			"submit_check_mail": "Pasitikrinkite el. paštą ir patvirtinkite registraciją.",
			"confirm_error": "Negalima patvirtinti prašymo.",
			"accept_button": "Patvirtinti pakvietima",
			"resend_confirmation_button": "Siųsti patvirtinimo laišką",
			"register_to": "Pasirinkite eilę",
			"type_queue_description": "<p>Ši registracija skirta <strong>jau studijuojantiems</strong> Vilniaus universitete.</p>\n<p>Kai tapsite pirmu savo eilėje, atsilaisvinus vietai, atitinkančiai Jūsų nurodytus poreikius, pakviesime Jus joje apsigyventi.</p>\n",
			"type_freshmen_description": "<p>Ši registraciją skirta <strong>tik būsimiems</strong> Vilniaus universiteto studentams.</p>\n<p>Rugpjūčio 13-14 dienomis, studentai gavę vietą bendrabutyje, gaus pranešimus į savo nurodytą el. paštą.</p>\n",
			"agreement_description": "<p>Šiais ir laukeliais iš jūsų paskyros bus dalinai užpildyta sutartis.</p>\n<p>Ją atsispausdinę galėsite greičiau užbaigti registraciją į bendrabutį.</p>\n<p>Adresą (Bendrabutį ir kambario numerį) ir mokestį sutartyje nurodys administratorius.</p>\n",
			"agreement_description_extension": "<p>Norint prasitęsti apgyvendinimo paslaugų sutartį turite užpildyti sutarties formą, kurią reikia atsispausdinti, pasirašyti ir atsiųsti bendrabučio administracijai.</p>\n<a href=\"https://bustas.vu.lt/bendrabuciai\" target=\"_blank\">Administracijos kontaktai.</a>\n",
			"study_cycle_mask_message_1": "Į pasirinktą miestelį gali registruotis tik bakalauro studijų programos studentai.",
			"study_cycle_mask_message_2": "Į pasirinktą miestelį gali registruotis tik magistrantūros studijų programos studentai.",
			"study_cycle_mask_message_3": "Į pasirinktą miestelį gali registruotis tik bakalauro arba magistrantūros studijų programos studentai.",
			"study_cycle_mask_message_4": "Į pasirinktą miestelį gali registruotis tik doktorantūros arba rezidentūros studijų programos studentai.",
			"study_cycle_mask_message_5": "Į pasirinktą miestelį gali registruotis tik bakalauro, doktorantūros arba rezidentūros studijų programos studentai.",
			"study_cycle_mask_message_6": "Į pasirinktą miestelį gali registruotis tik magistrantūros, doktorantūros arba rezidentūros studijų programos studentai.",
			"study_cycle_mask_message_7": "Į pasirinktą miestelį gali registruotis tik bakalauro, magistrantūros, doktorantūros arba rezidentūros studijų programos studentai.",
			"status": {
				"unconfirmed": "nepatvirtinta",
				"pending": "laukiama",
				"queued": "eilėje",
				"cancelled": "atšaukta",
				"invited": "pakviestas",
				"accepted": "patvirtintas",
				"completed": "apgyvendintas",
				"expired": "atmesta",
				"void": "anuliuota"
			}
		}
	},
	"admin": {
		"dashboard": {
			"title": "Darbalaukis",
			"queue": "Eilė",
			"freshmen": "Pirmakursiai",
			"search": "Paieška",
			"queue_status": "Eilės būsena",
			"in_queue": "viso eilėje",
			"available": "galima pakviesti",
			"matches": "atitinka pasirinktus kriterijus",
			"invite_title": "Pakviesti",
			"invite_link": "Pakviesti studentą",
			"invite_button": "Pakviesti",
			"invited_list": "Pakviesti studentai",
			"freshmen_list": "Pirmakursių sąrašas",
			"statistics": "Statistika",
			"statistics_by_faculty": "pagal fakultetus",
			"action_log": "Veiksmų žurnalas",
			"action_log_invited": "Pakvietė <strong>{{user}}</strong> apsigyventi <strong>{{campus}}</strong>\n(Eilėje nuo <strong>{{date}}</strong>)\n",
			"action_log_accepted_by_user": "Priėmė kvietimą apsigyventi bendrabutyje <strong>{{campus}}</strong>\n",
			"action_log_completed": "Apgyvendino <strong>{{user}}</strong> miestelyje <strong>{{campus}}</strong>\n",
			"action_log_cancelled_by_user": "Atšaukė savo prašymą apgyvendinti <strong>{{campus}}</strong> miestelyje\n",
			"action_log_cancelled": "Atšaukė <strong>{{user}}</strong> prašymą apgyvendinti <strong>{{campus}}</strong> miestelyje\n",
			"action_log_expired_by_user": "Nepriėmė kvietimo apsigyventi bendrabutyje per numatytą laiką (<strong>{{campus}}</strong>)\n",
			"action_log_accepted_expired_by_user": "Neatvyko pasirašyti sutarties per numatytą laiką (<strong>{{campus}}</strong>)\n",
			"action_log_void": "Anuliavo <strong>{{user}}</strong> prašymą apgyvendinti bendrabutyje <strong>{{campus}}</strong>\n",
			"invite_dialog": "Bus pakviestas vienas studentas iš eilės, ir jam išsiustas laiškas. Kviesti?",
			"settle_dialog": "Apgyvendinti studenta?",
			"void_dialog": "Anuliuoti prašyma?",
			"queue_disabled_message": "<div class=\"header\">Registracija uždaryta</div>\nStudentams išjungta galimybe registruotis į bendrą eilę.\n",
			"freshmen_disabled_message": "<div class=\"header\">Registracija uždaryta</div>\nPirmakursiams išjungta galimybe registruoti naujus prašymus.\n"
		},
		"application": {
			"settle": "Apgyvendinti studentą",
			"void": "Anuliuoti prašymą",
			"expire": "Padaryti prašyma negaliojančiu",
			"status": {
				"unconfirmed": "nepatvirtintas",
				"pending": "patvirtintas",
				"queued": "eilėje",
				"cancelled": "atšauktas",
				"invited": "pakviestas",
				"accepted": "atvykstantis",
				"completed": "apgyvendintas",
				"expired": "atmesta",
				"void": "anuliuotas"
			}
		}
	},
	"auth": {
		"login": {
			"title": "Prisijunkite",
			"description": "Nurodykite savo el. pašto adresą:",
			"password_for": "Įveskite vartotojo <strong>{{username}}</strong> slaptažodį:",
			"button": "Prisijungti",
			"forgot_password_link": "Pamiršote slaptažodį?",
			"loader_text": "Jungiamasi...",
			"check_email_message": "Patikrinkite savo elektroninį paštą.",
			"error_message": "Prisijungtu nepavyko. Pamėginkite vėliau."
		},
		"signup": {
			"title": "Registruotis",
			"description": "Norėdami užsiregistruoti, užpildykite šią formą.",
			"button": "Registruotis",
			"loader_text": "Jungiamasi..."
		},
		"password_reset": {
			"title": "Slaptažodžio priminimas",
			"description": "Nurodykite savo el. pašto adresą:",
			"button": "Siųsti priminimą",
			"success_message": "Laiškas su slaptažodžio atstatymo instrukcijomis išsiųstas el. paštu {{email}}",
			"error_message": "Negalima atstatyti Jūsų slaptažodžio. Mėginkite vėliau."
		},
		"password_change": {
			"title": "Slaptažodzio keitimas",
			"password_for": "Įveskite naują slaptažodį <strong>{{email}}</strong>:",
			"button": "Keisti slaptažodį",
			"success_message": "Slaptažodis pakeistas!"
		},
		"application_check": {
			"title": "Registracijos tikrinimas",
			"description": "Įveskite el. pašto adresą, kuriuo registravotės sistemoje:",
			"check_email_message": "Informacija buvo išsiusta i Jūsų elektroninį pašta.",
			"button": "Tikrinti"
		},
		"confirm": {
			"success_message": "Your have successfully confirmed your email account.",
			"error_message": "Could not confirm email, try again later."
		}
	},
	"error": {
		"generic": "Įvyko klaida!",
		"error": "Įvyko klaida!",
		"application": {
			"not_found": "Prašymas nerastas!",
			"abuse": "<div class=\"header\">Jūsų vardu jau yra registruotas prašymas gauti vietą bendrabutyje.</div>\nPrašome atšaukti arba paredaguoti savo prašymą prisijungus prie\n<a href=\"u/auth/?to=application.list\">savo paskyros</a>.\n",
			"abuse_different_email": "<div class=\"header\">Jūsų vardu jau yra registruotas prašymas gauti vietą bendrabutyje.</div>\nPrašymas buvo registruotas naudojant <strong>kitą elektroninio pašto adresą</strong>.<br>\nPrašome atšaukti arba paredaguoti savo prašymą prisijungus prie\n<a href=\"u/auth/?to=application.list\">savo paskyros</a> iš to elektroninio\npašto adreso, su kurio jūs pirma kartą registravotes.<br>\nJeigu jus neatsimenate, arba negalite prisijungti,\n<a href=\"u/support/contact\">susisiekite su mumis</a>.\n",
			"abuse_multiperson": "<div class=\"header\">Registruoti dviejų žmonių is vienos paskyros negalima.</div>\nRegistracijos sistema reikalauja, kad Jūs registruotumetes su savo asmeniniu, o\nne kito žmogaus elektroniniu paštu.\n",
			"tos_checkbox": "Pažymėkite, kad sutinkate su taisyklėmis!",
			"freshmen_cycle_disabled": "Jūsų pasirinktai studijų pakopai registracija šiuo metu negalima!",
			"personal_code_not_in_vu_database": "Jūsų asmens kodas nerastas VU studentų duomenų bazeje.",
			"files_required": "Jūs neprisegete dokumentų!",
			"campus_not_allowed": "Jūs negalite registruotis į šita miestelį!",
			"study_cycle_not_accepted": "Pažymekite varnelę arba pasirinkite kitą miestelį.",
			"queue_disabled": "Registracija į bendrą eilę šiuo metu uždaryta.",
			"freshmen_disabled": "Pirmakursių registracija šiuo metu uždaryta."
		},
		"user": {
			"not_found": "Vartotojas nerastas!",
			"invalid_credentials": "Neteisingas slaptažodis!",
			"invalid_email": "Neteisingas elektroninis paštas!",
			"short_password": "Slaptažodis per trumpas",
			"passwords_do_not_match": "Nesutampa slaptažodžiai!",
			"invalid_personal_code": "Neteisingas asmens kodas!",
			"duplicate_email": "Naudotojas su tokiu el. pašto adresu jau užregistruotas!"
		},
		"auth": {
			"not_authenticated": "Jūs neautentifikuoti!",
			"not_authorized": "Jūs neautorizuoti!",
			"passwords_do_not_match": "Slaptažodžiai nesutampa!",
			"old_password_invalid": "Neteisingas slaptažodis!",
			"email_not_found": "Nėra vartotojo su tokiu el. paštu!",
			"username_not_found": "Nėra vartotojo su tokiu prisijungimo vardu!",
			"token_invalid": "Autentifikavimo žymė negalioja."
		},
		"ldap": {
			"no_connection": "Nėra ryšio su LDAP serveriu!"
		},
		"recaptcha": {
			"invalid": "Neteisingai užpildyta Captcha."
		}
	}
}