'use strict';

const angular = require('angular');

// Define a module
const app = angular.module('vub-is.accom.auth', [
  require('../core'),
]);

app.controller('AuthController', require('./AuthController.js'));
app.controller('ConfirmController', require('./ConfirmController.js'));
app.controller('PasswordChangeController', require('./PasswordChangeController.js'));
app.controller('PasswordResetController', require('./PasswordResetController.js'));
app.controller('LoginTokenController', require('./LoginTokenController.js'));

// Setup the states
app.config(($stateProvider, authProvider) => {
  const authenticate = authProvider
    .authenticate((user) => user.isAdministrativeUser());
  $stateProvider.state('auth', {
    url: '/u/auth/?to',
    template: require('./templates/auth.html'),
    controller: 'AuthController as vm',
  });
  $stateProvider.state('auth_password_reset', {
    url: '/u/auth/password/reset/:code?',
    params: {
      email: null,
    },
    template: require('./templates/password_reset.html'),
    controller: 'PasswordResetController as vm',
  });
  $stateProvider.state('auth_password_change', {
    url: '/u/auth/password/change',
    template: require('./templates/password_change.html'),
    controller: 'PasswordChangeController as vm',
    resolve: {
      user: authenticate,
    },
  });
  $stateProvider.state('auth_confirm', {
    url: '/u/auth/confirm/:code',
    template: require('./templates/confirm.html'),
    controller: 'ConfirmController as vm',
  });
  $stateProvider.state('auth_login_token_sent', {
    template: require('./templates/login_token_sent.html'),
  });
  $stateProvider.state('auth_login_token_check', {
    url: '/u/auth/login/:code',
    template: require('./templates/login_token_check.html'),
    controller: 'LoginTokenController as vm',
  });
});

module.exports = app.name;
