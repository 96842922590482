'use strict';

module.exports =
/* @ngInject */
function (t, Entity) {

  class Faculty extends Entity {

    getName() {
      return this.name;
    }

    getAbbr() {
      return this.abbr;
    }

  }

  return Faculty;

};
