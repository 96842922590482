'use strict';

module.exports =
/* @ngInject */
function ($httpApi, $q, Campus) {

  let loadPromise = null;
  const instances = {};

  function withServices() {
    // TODO: Ensure faculties
    return $q.when();
  }

  function instantiate(res) {
    const instance = new Campus(res.data);
    instances[instance.id] = instance;
    return instance;
  }

  function instantiateMany(res) {
    return _.map(res.data, (item) => {
      const instance = new Campus(item);
      instances[instance.id] = instance;
      return instance;
    });
  }

  this.find = function (criteria) {
    return withServices()
      .then(() => $httpApi.get('/campus', { params: criteria }))
      .then((res) => instantiateMany(res));
  };

  this.load = function () {
    if (loadPromise) {
      return loadPromise;
    }
    loadPromise = this.find();
    return loadPromise;
  }

  this.get = function (id) {
    if (instances[id]) {
      return $q.resolve(instances[id]);
    }
    return withServices()
      .then(() => $httpApi.get('/campus/' + id))
      .then((res) => instantiate(res));
  };

  this.getAll = function () {
    return _.values(instances);
  };

  this.getInstance = function (id) {
    return instances[id];
  };

  this.update = function (item) {
    const obj = Campus.prototype.toJSON.call(item);
    return withServices()
      .then(() => $httpApi.put('/campus/' + item.id, obj))
      .then((res) => instantiate(res));
  };

}
