'use strict';

module.exports =
/* @ngInject */
function ($state, $stateParams, auth, error, t) {

  const vm = this;

  vm.user = {
    email: $stateParams.email,
  };

  vm.tokenValid = false;

  vm.hasCode = !!$stateParams.code;

  vm.error = null;
  vm.success = null;

  // Check password reset token
  if ($stateParams.code) {
    auth.checkPasswordResetToken($stateParams.code)
      .then((res) => {
        vm.tokenValid = res.valid;
        if (res.valid) {
          vm.user.email = res.email;
        } else {
          vm.error = t('error.auth.token_invalid');
        }
      })
      .catch(error.handler(vm, t('error.auth.token_invalid')));
  }

  vm.initiate = function (user) {
    auth.sendPasswordResetToken(user.email)
      .then(() => {
        vm.success = t('auth.password_reset.success_message', {
          email: user.email,
        });
      })
      .catch(error.handler(vm, t('auth.password_reset.error_message')));
  };

  vm.reset = function (user) {
    if (user.password !== user.password2) {
      vm.error = t('error.auth.passwords_do_not_match');
      return;
    }
    auth.resetPassword($stateParams.code, user.email, user.password)
      .then((res) => auth.login(user.email, user.password))
      .then((res) => {
        $state.transitionTo('index');
      })
      .catch(error.handler(vm, t('auth.password_reset.error_message')));
  };

}
