'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($httpApi, $q, t, User) {

  const instances = {};

  function withServices(promise) {
    return $q.all([
      User.ensureServices(),
    ]);
  }

  function instantiate(res) {
    const instance = new User(res.data);
    instances[instance.id] = instance;
    return instance;
  }

  function instantiateMany(res) {
    return _.map(res.data, (item) => {
      const instance = new User(item);
      instances[instance.id] = instance;
      return instance;
    });
  }

  this.find = function (criteria) {
    return withServices()
      .then(() => $httpApi.get('/user', { params: criteria }))
      .then((res) => instantiateMany(res));
  };

  this.get = function (id) {
    if (instances[id]) {
      return $q.resolve(instances[id]);
    }
    return withServices()
      .then(() => $httpApi.get('/user/' + id))
      .then((res) => instantiate(res));
  };

  this.create = function (item) {
    const obj = User.prototype.toJSON.call(item);
    return withServices()
      .then(() => $httpApi.post('/user', obj))
      .then((res) => instantiate(res));
  };

  this.update = function (item) {
    const obj = User.prototype.toJSON.call(item);
    return withServices()
      .then(() => $httpApi.put('/user/' + item.id, obj))
      .then((res) => instantiate(res));
  };

  this.remove = function (item) {
    const obj = User.prototype.toJSON.call(item);
    return withServices()
      .then(() => $httpApi.delete('/user/' + item.id, obj))
      .then((res) => instantiate(res));
  };


}
