'use strict';

module.exports =
/* @ngInject */
function ($timeout) {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      $timeout(() => element.focus().select(), 0);
    },
  };
}
