import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const DATE_FORMAT = 'YYYY-MM-DD';

class SemanticUiInput extends Component {

  render() {
    return (
      <div className="ui input">
        <input {...this.props} />
      </div>
    );
  }

}

export default function DatePicker(props) {
  return <ReactDatePicker {...props}
    selected={moment(props.value)}
    onChange={(date) => {
      props.onChange && props.onChange(date.format(DATE_FORMAT));
    }}
    customInput={<SemanticUiInput />}
    dateFormat={DATE_FORMAT} />;
};
