'use strict';

const angular = require('angular');

// Define a module
const app = angular.module('vub-is.accom.admin.settings', [
  require('../../core'),
]);

app.controller('CampusManagementController', require('./CampusManagementController.js'));
app.controller('SettingsController', require('./SettingsController.js'));
app.controller('UserManagementController', require('./UserManagementController.js'));

// Setup the states
app.config(($stateProvider, authProvider) => {
  const authAdmin = authProvider
    .authenticate((user) => user.isAdministrator());
  const authSuper = authProvider
    .authenticate((user) => user.isAdministrativeUser());
  $stateProvider.state('admin_settings', {
    url: '/a/settings/',
    template: require('./templates/settings.html'),
    controller: 'SettingsController as vm',
    resolve: {
      user: authAdmin,
      settings: (settingsService) => settingsService.load(),
    },
  });
  $stateProvider.state('admin_settings_users', {
    template: '<ui-view></ui-view>',
    params: {
      id: null,
    },
    controller: 'UserManagementController as vm',
    resolve: {
      user: authAdmin,
    },
  });
  $stateProvider.state('admin_settings_users.index', {
    url: '/a/settings/users',
    template: require('./templates/user_list.html'),
  });
  $stateProvider.state('admin_settings_users.create', {
    url: '/a/settings/users/create',
    template: require('./templates/user_create.html'),
  });
  $stateProvider.state('admin_settings_users.edit', {
    url: '/a/settings/users/{id}/edit',
    template: require('./templates/user_edit.html'),
  });
  $stateProvider.state('admin_settings_campuses', {
    template: '<ui-view></ui-view>',
    params: {
      id: null,
    },
    controller: 'CampusManagementController as vm',
    resolve: {
      user: authSuper,
    },
  });
  $stateProvider.state('admin_settings_campuses.index', {
    url: '/a/settings/campuses',
    template: require('./templates/campus_list.html'),
  });
  $stateProvider.state('admin_settings_campuses.edit', {
    url: '/a/settings/campuses/{id}/edit',
    template: require('./templates/campus_edit.html'),
  });
});

module.exports = app.name;
