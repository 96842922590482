'use strict';

const angular = require('angular');
const i18next = require('i18next');

// Define a module
const app = angular.module('vub-is.accom.i18n', []);

let DEFAULT_LANG = 'lt';
const RESOURCES = {
  // en: {
  //   translation: require('./translations/en.toml'),
  // },
  lt: {
    translation: require('./translations/lt.toml'),
  },
  // ru: {
  //   translation: require('./translations/ru.toml'),
  // },
};

function getStoredLang() {
  if (window.lang) {
    DEFAULT_LANG = window.lang;
  }
  // Workaround missing localStorage
  if (!localStorage) {
    return DEFAULT_LANG;
  }
  const lang = localStorage.getItem('lang');
  if (!lang || !RESOURCES[lang]) {
    localStorage.setItem('lang', DEFAULT_LANG);
    return DEFAULT_LANG;
  }
  return lang;
}

function setStoredLang(lang) {
  if (localStorage) {
    localStorage.setItem('lang', lang);
  }
  document.cookie = 'lang=' + lang;
  DEFAULT_LANG = lang;
  return lang;
}

// Initialize i18next
i18next.init({
  lng: getStoredLang(),
  resources: RESOURCES,
}, (err) => {
  if (err) {
    return console.error('i18n error:', err);
  }
});

// Translate function
function translate(str, options) {
  if (typeof str === 'boolean') {
    return i18next.t(str ? 'yes' : 'no', options);
  }
  return i18next.t(str, options);
};

// Translate service
app.factory('t', () => translate);

// Translate filter
app.filter('t', () => translate);

// i18n service
app.service('i18n', function () {
  this.getLang = function () {
    return getStoredLang();
  };
  this.setLang = function (lang) {
    setStoredLang(lang);
    location.reload();
  };
});

// i18n interceptor to translate API services
app.factory('i18nInterceptor', (i18n) => {
  return {
    'request': (config) => {
      config.headers['Accept-Language'] = i18n.getLang();
      return config;
    },
  };
});

// Directive: i18n-t
// Interpolate: i18n-html
// Watch: i18n-watch
// Options: i18n-options
app.directive('i18nT', () => {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      let options;
      if (typeof attrs.i18nOptions === 'string') {
        options = scope.$eval(attrs.i18nOptions, scope);
      }
      function updateText() {
        if (typeof attrs.i18nHtml === 'string') {
          element.html(translate(attrs.i18nT, options));
        } else {
          element.text(translate(attrs.i18nT, options));
        }
      }
      updateText();
      if (typeof attrs.i18nWatch === 'string') {
        attrs.$observe('i18nT', () => updateText());
      }
    }
  }
});

module.exports = app.name;
