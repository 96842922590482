'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, $stateParams, applicationService, t) {

  const vm = this;

  vm.query = null;
  vm.items = [];

  vm.search = function () {
    applicationService.search(vm.query)
      .then((items) => {
        vm.items = items;
      });
  };

  vm.view = function (item) {
    $state.go('admin_dashboard_view_application', { id: item.id });
  };

}
