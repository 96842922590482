'use strict';

module.exports =
/* @ngInject */
function ($state, $stateParams, auth, user, error, t) {

  const vm = this;

  vm.user = user;

  vm.change = function (user) {
    if (user.password !== user.password2) {
      vm.error = t('error.auth.passwords_do_not_match');
      return;
    }
    auth.changePassword(user.oldPassword, user.password)
      .then((res) => {
        vm.success = t('auth.password_change.success_message');
      })
      .catch((err) => {
        if (err && err.data && err.data.code === 'user.invalid_credentials') {
          vm.error = t('error.auth.old_password_invalid')
          return null;
        }
        throw err;
      })
      .catch(error.handler(vm, t('auth.password_reset.error_message')));
  };

}
