import React, { Component } from 'react';
import { Pagination } from 'semantic-ui-react';

export default class PaginatedTable extends Component {

  static defaultProps = {
    columns: [],
    collection: [],
    itemsPerPage: 15,
    renderItem: () => null,
    renderHeader: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  getTotalPages() {
    return (this.props.collection.length / this.props.itemsPerPage | 0) + 1;
  }

  getActivePage() {
    const totalPages = this.getTotalPages();
    if (this.state.activePage > totalPages) {
      return 1;
    }
    return this.state.activePage;
  }

  render() {
    const { props, state } = this;

    // Get pagination state
    const totalPages = this.getTotalPages();
    const activePage = this.getActivePage();

    // Count number of columns
    const tableHeader = props.renderHeader();
    const columnCount = React.Children
      .count(tableHeader && tableHeader.props.children);
    console.log(React.Children.count());

    // Slice the collection
    const sliceStart = (activePage - 1) * props.itemsPerPage;
    const sliceEnd = Math.min(
      props.collection.length,
      sliceStart + props.itemsPerPage);
    let collectionView = [];
    for (let i = sliceStart; i < sliceEnd; i++) {
      const x = props.collection[i];
      collectionView.push(props.renderItem(x, i));
    }

    // Pagination element
    const paginationElem = <Pagination
      secondary pointing size="mini"
      firstItem={null}
      lastItem={null}
      prevItem={{
        content: <i className="icon angle left" />,
        icon: true,
      }}
      nextItem={{
        content: <i className="icon angle right" />,
        icon: true,
      }}
      ellipsisItem={{
        content: <i className="icon ellipsis horizontal" />,
        icon: true,
      }}
      activePage={activePage}
      totalPages={totalPages}
      boundaryRange={3}
      siblingRange={2}
      onPageChange={(e, { activePage }) => {
        this.setState({ activePage });
        // window.scroll(0, 0);
      }} />

    return (
      <table className="ui table very small very compact striped">
        <thead>
          {tableHeader}
        </thead>
        <tbody>
          {props.collection.length === 0 && (
            <tr>
              <td colSpan={columnCount}>
                Nėra įrašų
              </td>
            </tr>
          )}
          {collectionView}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={columnCount}>
              {paginationElem}
            </th>
          </tr>
        </tfoot>
      </table>
    );
  }

}
