'use strict';

const angular = require('angular');

// Define a module
const app = angular.module('vub-is.accom.core', [
  require('angular-jwt'),
  require('angular-ui-router'),
  require('../i18n'),
]);

// Common services
app.service('applicationService', require('./services/ApplicationService.js'));
app.provider('auth', require('./services/AuthProvider.js'));
app.service('campusService', require('./services/CampusService.js'));
app.constant('countries', require('./services/countries.toml'));
app.service('facultyService', require('./services/FacultyService.js'));
app.service('error', require('./services/Error.js'));
app.service('settingsService', require('./services/SettingsService.js'));
app.service('userService', require('./services/UserService.js'));

// Entities
app.factory('Entity', require('./Entity.js'));
app.factory('Application', require('./entities/Application.js'));
app.factory('Campus', require('./entities/Campus.js'));
app.factory('Faculty', require('./entities/Faculty.js'));
app.factory('User', require('./entities/User.js'));

// Directives
app.directive('uiAutofocus', require('./directives/UiAutofocus.js'));
app.directive('uiDropdown', require('./directives/UiDropdown.js'));
app.directive('uiCheckbox', require('./directives/UiCheckbox.js'));
app.directive('uiPopup', require('./directives/UiPopup.js'));
app.directive('uiRecaptcha', require('./directives/UiRecaptcha.js'));

// Components
app.directive('uiBarChart', require('./components/UiBarChart.js'));
app.component('uiFilterButton', require('./components/UiFilterButton.js'));
app.directive('uiForm', require('./components/UiForm.js'));
app.component('uiFormCheckbox', require('./components/UiFormCheckbox.js'));
app.component('uiFormDatePicker', require('./components/UiFormDatePicker.js'));
app.component('uiFormDropdown', require('./components/UiFormDropdown.js'));
app.component('uiFormText', require('./components/UiFormText.js'));
app.component('uiFormTextarea', require('./components/UiFormTextarea.js'));

module.exports = app.name;
