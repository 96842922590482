'use strict';

const _ = require('lodash');

function boolAttr(vm, attrs, id) {
  if (typeof attrs[id] === 'string' && attrs[id].length === 0) {
    vm[id] = true;
  }
}

module.exports = {
  template: require('./UiFormTextarea.html'),
  bindings: {
    model: '=',
    disabled: '<?',
    required: '<?',
    autofocus: '<?',
    rows: '<?',
    label: '@',
    name: '@',
    placeholder: '@',
  },
  /* @ngInject */
  controller: function ($scope, $element, $attrs) {
    const $ctrl = this;
    const $form = $scope.$parent.$form;

    // Convert attributes without values to 'true'
    boolAttr($ctrl, $attrs, 'disabled');
    boolAttr($ctrl, $attrs, 'required');
    // Default to 'text' type
    if (!$attrs.type) {
      $ctrl.type = 'text';
    }

    $ctrl.$postLink = function () {
      const $input = $element.find('textarea');
      // Add top level classes
      $element.addClass('field');
      // Required flag
      if ($ctrl.required) {
        $element.addClass('required');
      }
      // Autofocus flag
      if (typeof $attrs.autofocus === 'string') {
        setTimeout(() => $input.focus().select(), 0);
      }
      // Assign form object to controller for error handling
      if ($ctrl.name && $form && $form[$ctrl.name]) {
        $ctrl.form = $form[$ctrl.name];
      }
    };

    $ctrl.onChange = function () {
      if ($ctrl.form) {
        $ctrl.form.$setValidity('custom', true);
      }
    };
  },
};
