import React, { Component, Fragment } from 'react';
import { Button, Icon, Tab, Input, Checkbox, Modal, Header, Dropdown } from 'semantic-ui-react';
import { debounce, uniqBy } from 'lodash';
import ButtonDropdown from './ButtonDropdown.jsx';
import LabeledButton from './LabeledButton.jsx';
import PaginatedTable from './PaginatedTable.jsx';
import TinyButton from './TinyButton.jsx';
import UploadButton from './UploadButton.jsx';
import DatePicker from './DatePicker.jsx';
import CSV from '../CSV.js';
import { readFileAsText, serveTextAsFile, formatAsDate, bindMethods } from '../utils.js';

const STUDY_CYCLES = [
  'Visos pakopos',
  'Bakalaurai',
  'Magistrai',
  'Doktorantai',
];

const ALL_STATUSES = [
  // Normal status flow
  'unconfirmed',
  'pending',
  'queued',
  'invited',
  'accepted',
  'completed',
  // Special statuses
  'cancelled',
  'expired',
  'void',
];

const FN_IDENTITY = (x) => x;

const CSV_COLUMN_MAPPING = new Map([
  ['Vardas', {
    propName: 'firstName',
    transform: FN_IDENTITY,
  }],
  ['Pavardė', {
    propName: 'lastName',
    transform: FN_IDENTITY,
  }],
  ['El. paštas', {
    propName: 'email',
    transform: FN_IDENTITY,
  }],
  ['Asmens kodas', {
    propName: 'personalCode',
    transform: FN_IDENTITY,
  }],
  ['Fakultetas', {
    propName: 'facultyName',
    transform: FN_IDENTITY,
  }],
  ['Studijų programa', {
    propName: 'programme',
    transform: FN_IDENTITY,
  }],
  ['Gatvė', {
    propName: 'street',
    transform: FN_IDENTITY,
  }],
  ['Gyvenvietė', {
    propName: 'region',
    transform: FN_IDENTITY,
  }],
  ['Indeksas', {
    propName: 'postalCode',
    transform: FN_IDENTITY,
  }],
  ['Miestas', {
    propName: 'city',
    transform: FN_IDENTITY,
  }],
  ['Telefonai', {
    propName: 'phone',
    transform: FN_IDENTITY,
  }],
  ['Lytis', {
    propName: 'sex',
    transform: (x) => {
      if (x.toLowerCase() === 'v') {
        return 'male';
      }
      if (x.toLowerCase() === 'm') {
        return 'female';
      }
    },
  }],
]);

function objectContainsText(obj, searchText) {
  const words = searchText.split(' ').filter((x) => !!x);
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }
    const value = obj[i];
    if (typeof value === 'string' && words.every((x) => value.includes(x))) {
      // console.log(searchText, value, obj);
      return true;
    }
  }
  return false;
}

export default class FreshmenPage extends Component {

  constructor(props) {
    super(props);
    // Component state
    this.state = {
      freshmen: [],
      freshmenFilters: {
        searchText: '',
        activeStudyCycle: 0,
        activeFaculty: 0,
        adminStatus: 0,
      },
      candidates: [],
      candidateFaculties: [],
      candidateFilters: {
        searchText: '',
        activeFaculty: 0,
        showRegisteredOnly: true,
        showPendingOnly: false,
      },
      invitationDate: formatAsDate(new Date()),
      invitationAddress: null,
      templates: [
        {
          key: 'freshmen_new_information',
          text: 'Priminimas',
        },
      ],
      template: '',
      invitationCampus: '',
      invitationAddress: '',
      objects: [],
    };
    //conts with access to props
    this.ADMIN_STATUSES = [
      'Visos būsenos',
      'registruotas'
    ].concat(ALL_STATUSES.map((x) => props.t('admin.application.status.' + x)));

    this.ADMIN_STATUSES_INDEX_MAP = Object.assign({
      "0": 'Visos būsenos',
      "1": 'registruotas'
    }, (ALL_STATUSES.reduce(function (result, item, index) {
      result[index + 2 + ""] = props.t('admin.application.status.' + item);
      return result;
    }, {})));
    // Storage for various things after render
    this.stash = {};
    // Bind methods to object
    bindMethods(this, [
      'importCandidates',
      'exportCandidates',
      'exportFreshmen',
      'expireFreshmenBulk',
      'closeApplications'
    ]);
  }

  setStateIn(key, updateObj) {
    this.state[key] = Object.assign({}, this.state[key], updateObj);
    this.forceUpdate();
  }

  async componentWillMount() {
    const {
      $httpApi,
      applicationService,
      facultyService,
      campusService,
    } = this.props;
    // Freshmen
    const freshmen = await applicationService.find({
      dashboard: true,
      type: 'freshmen',
    });
    this.setState({ freshmen });
    // Faculties
    await facultyService.load();
    const faculties = facultyService.getAll();
    // This one is used for the dropdowns
    faculties.unshift({
      name: 'Visi fakultetai',
    });
    this.setState({ faculties });
    // Campuses
    await campusService.load();
    const campuses = campusService.getAll();
    this.setState({ campuses });
    // Objects
    const objectsRes = await $httpApi.get('/upload/object');
    const objects = objectsRes.data.objects;
    this.setState({ objects });
  }

  async importCandidates(file) {
    let csvData;
    if (file instanceof File) {
      // if (file.type !== 'text/csv') {
      //   console.log(file);
      //   throw new Error(`Expected a "text/csv" file, got "${file.type}"`);
      // }
      const text = await readFileAsText(file);
      // Parse and prepare CSV
      const csv = new CSV(text);
      csvData = csv.parse();
      // Save csvData for saving later
      this.stash.csvData = csvData;
      this.setState({ objectUuid: null });
    }
    else {
      const objRes = await this.props.$httpApi.get(`/upload/object?uuid=${file}`);
      const obj = objRes.data;
      csvData = obj.csvData;
      this.stash.csvData = null;
      this.setState({ objectUuid: file });
    }
    const csvHeader = csvData[0];
    const csvBody = csvData.slice(1);
    // Build the candidates collection
    const candidates = csvBody.map((row, i) => {
      const candidate = {};
      // Save raw CSV
      candidate.csvRow = row;
      // Map CSV to object
      row.forEach((value, i) => {
        const mapping = CSV_COLUMN_MAPPING.get(csvHeader[i]);
        if (mapping) {
          // Transform value and assign to the object
          candidate[mapping.propName] = mapping.transform(value);
        }
      });
      // Generate a unique id
      candidate.id = `${candidate.personalCode}_${i}`;
      // Find a matching freshmen application
      candidate.application = this.state.freshmen.find((x) => {
        return !x.hasTerminalStatus()
          && x.personalCode === candidate.personalCode;
      });
      // // Find a matching faculty
      // candidate.faculty = this.state.faculties.find((x) => {
      //   return x.name === candidate.facultyName;
      // });
      return candidate;
    });
    // Build the candidate faculty list
    const candidateFaculties = uniqBy(candidates, (x) => x.facultyName)
      .map((x) => x.facultyName);
    candidateFaculties.unshift('Visi fakultetai');
    // Update state
    this.setState({
      candidates,
      candidateFaculties,
      csvHeader,
    });
  }

  async exportCandidates() {
    const candidates = this.stash.filteredCandidates;
    if (!candidates || candidates.length === 0) {
      window.alert('Nothing to export!');
      return;
    }
    // Build CSV
    const data = candidates.map((x) => x.csvRow);
    data.unshift(this.state.csvHeader);
    const csv = new CSV(data);
    // Serve the file
    serveTextAsFile('export-candidates.csv', csv.encode());
  }

  async saveCandidates() {
    if (!this.stash.csvData) {
      return;
    }
    const name = window.prompt('Enter the name for this file:');
    if (!name) {
      return;
    }
    const res = await this.props.$httpApi.post('/upload/object', {
      name,
      csvData: this.stash.csvData,
    });
    this.stash.csvData = null;
    this.setState({ objectUuid: res.data.uuid });
    await this.componentWillMount();
  }

  async deleteCandidates(uuid) {
    await this.props.$httpApi.delete(`/upload/object?uuid=${uuid}`);
    this.stash.csvData = null;
    this.setState({
      objectUuid: null,
      candidates: [],
    });
    await this.componentWillMount();
  }

  async exportFreshmen() {
    const freshmen = this.stash.filteredFreshmen;
    if (!freshmen || freshmen.length === 0) {
      window.alert('Nothing to export!');
      return;
    }
    // Build CSV
    const header = [
      'Vardas',
      'Pavardė',
      'El. paštas',
      'Asmens kodas',
      'Fakultetas',
      'Studijų pakopa',
      'Soc. remtinas',
      'Lytis',
    ];
    const data = freshmen.map((x) => [
      x.firstName,
      x.lastName,
      x.email,
      x.personalCode,
      x.faculty && x.faculty.name,
      x.studyCycle,
      x.disadvantaged ? '1' : '0',
      x.sex === 'female' ? 'M' : 'V',
    ]);
    data.unshift(header);
    const csv = new CSV(data);
    // Serve the file
    serveTextAsFile('export-freshmen.csv', csv.encode());
  }

  async expireFreshmenBulk() {
    if (!window.confirm('Are you sure?')) {
      return null;
    }
    // Send the bulk expiry request
    await this.props.applicationService.expireFreshmenBulk();
    // Re-initialize the component
    await this.componentWillMount();
  };

  async closeApplications(freshmen) {
    if (!window.confirm('Are you sure?')) {
      return null;
    }
    // Send the bulk expiry request
    await this.props.applicationService.closeApplications(freshmen);
    // Re-initialize the component
    await this.componentWillMount();
  };

  render() {
    const { state, props } = this;

    // Freshmen
    const freshmen = state.freshmen
      .filter((x) => {
        return (state.freshmenFilters.activeStudyCycle === 0
          || state.freshmenFilters.activeStudyCycle === x.studyCycle)
          && (state.freshmenFilters.adminStatus === 0
            || this.ADMIN_STATUSES_INDEX_MAP[state.freshmenFilters.adminStatus] == x.getLocalAdminStatus())
          && (state.freshmenFilters.searchText === ''
            || objectContainsText(x, state.freshmenFilters.searchText))
      });

    // Cache current candidates
    this.stash.filteredFreshmen = freshmen;

    // Candidates
    const candidates = state.candidates
      .filter((x) => {
        return (state.candidateFilters.activeFaculty === 0
          || state.candidateFaculties[state.candidateFilters.activeFaculty] === x.facultyName)
          && (state.candidateFilters.searchText === ''
            || objectContainsText(x, state.candidateFilters.searchText))
          && (!state.candidateFilters.showRegisteredOnly
            || !!x.application)
          && (!state.candidateFilters.showPendingOnly
            || (x.application && (x.application.type === 'freshmen' && x.application.status === "pending")))
      });
    const registeredCandidates = candidates.filter((x) => !!x.application);

    // Cache current candidates
    this.stash.filteredCandidates = candidates;

    // Setup tabs
    const tabPanes = [];

    tabPanes.push({
      menuItem: 'Registruoti pirmakursiai',
      render: () => (
        <Tab.Pane>
          <div className="ui grid">
            <div className="twelve wide column">
              <LabeledButton
                text="Export"
                icon="download"
                onClick={this.exportFreshmen} />
              <ButtonDropdown
                text="Studijų pakopa"
                items={STUDY_CYCLES}
                value={state.freshmenFilters.activeStudyCycle}
                onChange={(x) => {
                  this.setStateIn('freshmenFilters', { activeStudyCycle: x });
                }} />
              <ButtonDropdown
                text="Registracijos būsena"
                items={this.ADMIN_STATUSES}
                value={state.freshmenFilters.adminStatus}
                onChange={(x) => {
                  this.setStateIn('freshmenFilters', { adminStatus: x });
                }} />
              <Input icon="search" placeholder="Ieškoti..."
                value={state.freshmenFilters.searchText}
                onChange={(e, data) => {
                  this.setStateIn('freshmenFilters', {
                    searchText: data.value,
                  });
                }} />
            </div>
            <div className="four wide right aligned column">
              <LabeledButton icon="warning sign" color="orange"
                text="Expire all"
                onClick={this.expireFreshmenBulk} />
            </div>
          </div>
          <PaginatedTable
            collection={freshmen}
            renderHeader={() => (
              <tr>
                <th>Vardas, pavardė</th>
                <th>Asmens kodas</th>
                <th className="collapsing"><abbr title="Fakultetas">Fak.</abbr></th>
                <th className="collapsing"><abbr title="Studijų pakopa">SP</abbr></th>
                <th className="collapsing"><abbr title="Socialiai remtinas">Soc.</abbr></th>
                <th className="collapsing">Sukūrimo data</th>
                <th className="collapsing">Būsena</th>
                <th className="collapsing"></th>
              </tr>
            )}
            renderItem={(item, i) => (
              <tr key={item.id}>
                <td>{item.getFullName()}</td>
                <td>
                  {item.personalCode}
                  {item.sex === 'male' && <Icon name="male" color="blue" />}
                  {item.sex === 'female' && <Icon name="female" color="pink" />}
                </td>
                <td>
                  {item.faculty && (
                    <abbr title={item.faculty.getName()}>
                      {item.faculty.getAbbr()}
                    </abbr>
                  )}
                </td>
                <td>{item.studyCycle}</td>
                <td className="collapsing">
                  {item.disadvantaged ? 'taip' : 'ne'}
                </td>
                <td className="collapsing">
                  {formatAsDate(item.createdAt)}
                </td>
                <td className="collapsing">
                  {item.getLocalAdminStatus()}
                </td>
                <td className="collapsing right aligned">
                  {item.isAccepted() && (
                    <TinyButton icon="checkmark" color="green"
                      text="Settle"
                      onClick={async () => {
                        const confirmed = window.confirm('Ar tikrai norite apgyvendinti studentą?');
                        if (!confirmed) {
                          return false;
                        }
                        await props.applicationService.settle(item);
                        this.componentWillMount();
                      }} />
                  )}
                  {item.isAccepted() && (
                    <TinyButton icon="remove" color="red"
                      text="Void"
                      onClick={async () => {
                        const confirmed = window.confirm('Ar tikrai norite anuliuoti prašymą?');
                        if (!confirmed) {
                          return false;
                        }
                        await props.applicationService.void(item);
                        this.componentWillMount();
                      }} />
                  )}
                  <TinyButton icon="arrow right" text="Rodyti"
                    href={`/a/dashboard/view/${item.id}`}
                    target="_blank" />
                </td>
              </tr>
            )} />
          <LabeledButton icon="dropbox" color="yellow"
            text={"Atmesti prašymus (" + freshmen.length + ")"}
            onClick={(e) => this.closeApplications(freshmen)} />
        </Tab.Pane>
      ),
    });

    tabPanes.push({
      menuItem: 'VU sąrašas',
      render: () => (
        <Tab.Pane>
          <div>
            <Dropdown floating labeled button className="icon"
              text="Import" icon="upload"
              open={this.state.importDropdownOpen}
              onOpen={() => this.setState({ importDropdownOpen: true })}
              onClose={() => this.setState({ importDropdownOpen: false })}>
              <Dropdown.Menu>
                <Dropdown.Item as="label">
                  Upload
                  <input type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      this.setState({ importDropdownOpen: false })
                      this.importCandidates(e.target.files[0]);
                    }} />
                </Dropdown.Item>
                <Dropdown.Divider />
                {state.objects.map((obj) => (
                  <Dropdown.Item key={obj.uuid}
                    content={obj.name || obj.uuid}
                    onClick={() => this.importCandidates(obj.uuid)} />
                ))}
                {state.objects.length === 0 && (
                  <Dropdown.Item disabled content="Nėra išsaugotų failų" />
                )}
              </Dropdown.Menu>
            </Dropdown>
            <LabeledButton
              text="Export"
              icon="download"
              onClick={this.exportCandidates} />
            {this.stash.csvData && (
              <Button icon="save" color="green"
                onClick={() => this.saveCandidates()} />
            )}
            {this.state.objectUuid && (
              <Button icon="delete" color="red"
                onClick={() => this.deleteCandidates(this.state.objectUuid)} />
            )}
            <ButtonDropdown
              text="Fakultetas"
              items={state.candidateFaculties}
              value={state.candidateFilters.activeFaculty}
              mapToDescription={(facultyName, i) => {
                // Show a number of candidates in each faculty
                // Number without filter
                if (i === 0) {
                  return state.candidates.length;
                }
                // Number with filter
                return state.candidates
                  .filter((x) => facultyName === x.facultyName)
                  .length;
              }}
              onChange={(x) => {
                this.setStateIn('candidateFilters', { activeFaculty: x });
              }} />
            <Input icon="search" placeholder="Ieškoti..."
              value={state.candidateFilters.searchText}
              onChange={(e, data) => {
                this.setStateIn('candidateFilters', {
                  searchText: data.value,
                });
              }} />
          </div>
          <div className="ui vertical segment form">
            <div className="field">
              <Checkbox label="Rodyti tik registruotus"
                checked={state.candidateFilters.showRegisteredOnly}
                onChange={(e, data) => {
                  this.setStateIn('candidateFilters', {
                    showRegisteredOnly: data.checked,
                  });
                }} />
            </div>
            <div className="field">
              <Checkbox label="Rodyti tik nepakviestus"
                checked={state.candidateFilters.showPendingOnly}
                onChange={(e, data) => {
                  this.setStateIn('candidateFilters', {
                    showPendingOnly: data.checked,
                  });
                }} />
            </div>
          </div>
          <PaginatedTable
            collection={candidates}
            renderHeader={() => (
              <tr>
                <th>Vardas, pavardė</th>
                <th>Asmens kodas</th>
                <th>Fakultetas</th>
                <th>Studijų programa</th>
                <th className="collapsing">Būsena</th>
                <th className="collapsing"></th>
              </tr>
            )}
            renderItem={(item, i) => (
              <tr key={item.id}>
                <td>{item.firstName + ' ' + item.lastName}</td>
                <td>
                  {item.personalCode}
                  {item.sex === 'male' && <Icon name="male" color="blue" />}
                  {item.sex === 'female' && <Icon name="female" color="pink" />}
                </td>
                <td>{item.facultyName}</td>
                <td>{item.programme}</td>
                <td className="collapsing">
                  {item.application && item.application.getLocalAdminStatus()}
                </td>
                <td className="collapsing right aligned">
                  {item.application && (
                    <TinyButton icon="arrow right" text="Rodyti"
                      href={`/a/dashboard/view/${item.application.id}`}
                      target="_blank" />
                  )}
                </td>
              </tr>
            )} />
          {registeredCandidates.length !== 0 && (
            <div className="ui vertical segment">
              <Modal basic size="small"
                open={state.modalOpened}
                onOpen={() => this.setState({ modalOpened: true })}
                trigger={(
                  <LabeledButton
                    text={`Kviesti (${registeredCandidates.length})`}
                    color="blue"
                    icon="envelope open outline" />
                )}>
                <Header icon="envelope open outline"
                  content="Siųsti kvietimus" />
                {state.invitationSending && (
                  <Modal.Content>
                    <div className="ui loader massive inverted"></div>
                  </Modal.Content>
                ) || (
                    <Modal.Content>
                      <p>Jūs kviečiate {registeredCandidates.length} studentų
                    pagal šiuos kriterijus:</p>
                      <ul>
                        {state.candidateFilters.activeFaculty !== 0 && (
                          <li>Fakultetas: <strong>
                            {state.candidateFaculties[state.candidateFilters.activeFaculty]}
                          </strong></li>
                        )}
                        <li>Registruotas sistemoje kaip pirmakursis</li>
                        {state.candidateFilters.showPendingOnly && (
                          <li>Registruotas sistemoje, bet nepakviestas</li>
                        )}
                      </ul>
                      <div>Pasirašymo data:<br />
                        <DatePicker
                          value={state.invitationDate}
                          onChange={(date) => {
                            this.setState({ invitationDate: date });
                          }} />
                      </div>
                      <div>Pasirašymo adresas:<br />
                        <Input value={state.invitationAddress}
                          onChange={(e, data) => {
                            this.setState({ invitationAddress: data.value });
                          }} />
                      </div>
                      <div>Pasirinkite miestelį:<br />
                        <ButtonDropdown
                          text="Miestelis"
                          items={state.campuses}
                          value={state.invitationCampus}
                          mapToKey={(x) => x.id}
                          mapToValue={(x) => x}
                          mapToText={(x) => x.abbr}
                          onChange={(x) => {
                            this.setState({ invitationCampus: x });
                          }} />
                      </div>
                    </Modal.Content>
                  )}
                <Modal.Actions>
                  <Button basic color="red" inverted
                    onClick={() => this.setState({ modalOpened: false })}>
                    <Icon name="remove" />
                    Atšaukti
                  </Button>
                  <Button color="teal" inverted
                    onClick={async () => {
                      console.log('inviting...');
                      try {
                        this.setState({ invitationSending: true });
                        const res = await props.applicationService.inviteFreshmen(
                          registeredCandidates.map((x) => x.application),
                          state.invitationCampus, state.invitationDate,
                          state.invitationAddress);
                        console.log('OK', res);
                      }
                      finally {
                        this.setState({ invitationSending: false });
                      }
                      this.setState({ modalOpened: false });
                    }}>
                    <Icon name="paper plane outline" />
                    Siųsti kvietimus ({registeredCandidates.length})
                  </Button>
                </Modal.Actions>
              </Modal>
              <Modal basic size="small"
                open={state.modalSendOpened}
                onOpen={() => this.setState({ modalSendOpened: true })}
                trigger={(
                  <LabeledButton
                    text={`Siųsti laišką (${registeredCandidates.length})`}
                    color="olive"
                    icon="envelope open outline" />
                )}>
                <Header icon="envelope open outline"
                  content="Siųsti laišką" />
                {state.invitationSending && (
                  <Modal.Content>
                    <div className="ui loader massive inverted"></div>
                  </Modal.Content>
                ) || (
                    <Modal.Content>
                      <p>Jūs siunčiate laišką {registeredCandidates.length} studentų
                    pagal šiuos kriterijus:</p>
                      <ul>
                        {state.candidateFilters.activeFaculty !== 0 && (
                          <li>Fakultetas: <strong>
                            {state.candidateFaculties[state.candidateFilters.activeFaculty]}
                          </strong></li>
                        )}
                        <li>Registruotas sistemoje kaip pirmakursis</li>
                        {state.candidateFilters.showPendingOnly && (
                          <li>Registruotas sistemoje, bet nepakviestas</li>
                        )}
                      </ul>
                      <div>Pasirašymo data:<br />
                        <DatePicker
                          value={state.invitationDate}
                          onChange={(date) => {
                            this.setState({ invitationDate: date });
                          }} />
                      </div>
                      <div>Pasirašymo adresas:<br />
                        <Input value={state.invitationAddress}
                          onChange={(e, data) => {
                            this.setState({ invitationAddress: data.value });
                          }} />
                      </div>
                      <div>Pasirinkite miestelį:<br />
                        <ButtonDropdown
                          text="Miestelis"
                          items={state.campuses}
                          value={state.invitationCampus}
                          mapToKey={(x) => x.id}
                          mapToValue={(x) => x}
                          mapToText={(x) => x.abbr}
                          onChange={(x) => {
                            this.setState({ invitationCampus: x });
                          }} />
                      </div>
                      <div>Šablonas:<br />
                        <ButtonDropdown
                          text="Šablonas"
                          items={state.templates}
                          value={state.template}
                          mapToKey={(x) => x.key}
                          mapToValue={(x) => x}
                          mapToText={(x) => x.text}
                          onChange={(x) => {
                            this.setState({ template: x });
                          }} />
                      </div>
                    </Modal.Content>
                  )}
                <Modal.Actions>
                  <Button basic color="red" inverted
                    onClick={() => this.setState({ modalSendOpened: false })}>
                    <Icon name="remove" />
                    Atšaukti
                  </Button>
                  <Button color="teal" inverted
                    onClick={async () => {
                      console.log('sending...');
                      try {
                        this.setState({ invitationSending: true });
                        const res = await props.applicationService.sendMail(
                          registeredCandidates.map((x) => x.application),
                          state.invitationCampus, state.invitationDate,
                          state.invitationAddress,
                          state.template.key);
                        console.log('OK', res);
                      }
                      finally {
                        this.setState({ invitationSending: false });
                      }
                      this.setState({ modalSendOpened: false });
                    }}>
                    <Icon name="paper plane outline" />
                    Siųsti ({registeredCandidates.length})
                  </Button>
                </Modal.Actions>
              </Modal>
            </div>
          )}
        </Tab.Pane>
      ),
    });

    return <Tab panes={tabPanes} />
  }

}
