'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($httpApi, $q, t, Application, User) {

  let instances = {};

  // Room types enum
  this.roomTypes = {
    any: 'room_type.any',
    double: 'room_type.double',
    double_1: 'room_type.double_1',
    triple: 'room_type.triple',
    triple_2: 'room_type.triple_2',
    triple_1: 'room_type.triple_1',
  };

  this.studyCycles = {
    1: 'Bakalauro',
    4: 'Vientisos',
    2: 'Magistro',
    3: 'Doktorantūros/Rezidentūros',
  };

  function withServices() {
    return $q.all([
      Application.ensureServices(),
    ]);
  }

  function instantiate(res) {
    const instance = new Application(res.data);
    instances[instance.id] = instance;
    return instance;
  }

  function instantiateMany(res) {
    return _.map(res.data, (item) => {
      const instance = new Application(item);
      instances[instance.id] = instance;
      return instance;
    });
  }

  this.clearInstances = () => {
    instances = {};
    return this;
  }

  this.find = function (criteria) {
    return withServices()
      .then(() => $httpApi.get('/application', { params: criteria }))
      .then(res => instantiateMany(res));
  };

  this.search = function (q) {
    return withServices()
      .then(() => $httpApi.get('/application/search', { params: { q } }))
      .then(res => instantiateMany(res));
  };

  this.get = function (id) {
    if (instances[id]) {
      return $q.resolve(instances[id]);
    }
    return withServices()
      .then(() => $httpApi.get('/application/' + id))
      .then(res => instantiate(res));
  };

  this.submit = function (item, captcha) {
    const obj = Application.prototype.toJSON.call(item);
    obj.captcha = captcha;
    return $httpApi.post('/application', obj);
  };

  this.update = function (item) {
    const obj = Application.prototype.toJSON.call(item);
    return $httpApi.put('/application/' + item.id, obj);
  };

  this.confirm = function (id) {
    return $httpApi.post('/application/' + id + '/confirm');
  };

  this.cancel = function (item) {
    return $httpApi.post('/application/' + item.id + '/cancel')
      .then(() => {
        item.status = 'cancelled';
        return item;
      });
  };

  this.void = function (item) {
    return $httpApi.post('/application/' + item.id + '/void')
      .then(() => {
        item.status = 'void';
        return item;
      });
  };

  this.accept = function (item) {
    return $httpApi.post('/application/' + item.id + '/accept')
      .then(() => {
        item.status = 'accepted';
        return item;
      });
  };

  this.settle = function (item) {
    return $httpApi.post('/application/' + item.id + '/settle')
      .then(() => {
        item.status = 'completed';
        return item;
      });
  };

  this.sendConfirmationToken = function (item) {
    return $httpApi.post('/application/' + item.id + '/send_token');
  };

  this.getQueueSize = function (criteria) {
    return $httpApi.post('/application/queue/size', criteria)
      .then(res => res.data);
  };

  this.getQueueStatistics = function (criteria) {
    return $httpApi.post('/application/queue/statistics', criteria)
      .then(res => res.data);
  };

  this.invite = function (criteria) {
    return $httpApi.post('/application/invite', criteria);
  };

  this.inviteFreshmen = function (freshmenList, campus, invitationDate, invitationAddress) {
    return $httpApi.post('/application/invite/freshmen', {
      ids: freshmenList.map((x) => x.id),
      campusId: campus.id,
      invitationDate,
      invitationAddress,
    });
  };

  this.sendMail = function (freshmenList, campus, invitationDate, invitationAddress, template) {
    return $httpApi.post('/application/send', {
      ids: freshmenList.map((x) => x.id),
      campusId: campus.id,
      invitationDate,
      invitationAddress,
      template
    });
  };

  this.getActionLog = function (criteria) {
    return withServices()
      .then(() => $httpApi.get('/application/log', { params: criteria }))
      .then(res => {
        return _.map(res.data, (item) => {
          if (item.application) {
            item.application = new Application(item.application);
          }
          if (item.user) {
            item.user = new User(item.user);
          }
          return item;
        });
      });
  };

  this.expireFreshmenBulk = function () {
    return $httpApi.post('/application/expire_freshmen_bulk');
  };

  this.closeApplications = function (applications) {
    return $httpApi.post('/application/close_applications', {
      applications: applications.map((x) => x.id),
    });
  };

  this.deleteFile = (appl, file, reason) => {
    return $httpApi.post(`/application/${appl.id}/deleteFile`, {
      file,
      reason,
    });
  };

}
