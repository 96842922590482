'use strict';

const React = require('react');
const ReactDOM = require('react-dom');
const FreshmenPage = require('../../react/components/FreshmenPage.jsx').default;

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, $scope, $element, $q, $httpApi, user, settings,
    applicationService, facultyService, campusService, t) {

  const vm = this;

  const reactElement = React.createElement(FreshmenPage, {
    // $state, $scope, $element, $q,
    $httpApi, user, settings, applicationService,
    facultyService, campusService, t,
  });
  const reactRoot = $element.find('.react-root')[0];

  ReactDOM.render(reactElement, reactRoot);

  $scope.$on('$destroy', () => {
    ReactDOM.unmountComponentAtNode(reactRoot);
  });

  vm.user = user;
  vm.settings = settings.getAll();

}
