'use strict';

const angular = require('angular');

// Define a module
const app = angular.module('vub-is.accom.admin.dashboard', [
  require('../../core'),
]);

app.controller('ApplicationViewController', require('./ApplicationViewController.js'));
app.controller('FreshmenController', require('./FreshmenController.js'));
app.controller('InviteController', require('./InviteController.js'));
app.controller('QueueController', require('./QueueController.js'));
app.controller('SearchController', require('./SearchController.js'));

// Setup the states
app.config(($stateProvider, authProvider) => {
  const authenticate = authProvider
    .authenticate((user) => user.isAdministrativeUser());
  // The 'admin_dashboard' nested route
  $stateProvider.state('admin_dashboard', {
    abstract: true,
    template: require('./templates/dashboard.html'),
    resolve: {
      user: authenticate,
    },
  });
  $stateProvider.state('admin_dashboard.index', {
    url: '/a/dashboard',
    onEnter: ($state) => $state.go('admin_dashboard.queue'),
  });
  $stateProvider.state('admin_dashboard.queue', {
    url: '/a/dashboard/queue',
    controller: 'QueueController as vm',
    template: require('./templates/queue_list.html'),
    resolve: {
      faculties: (facultyService) => facultyService.load(),
      settings: (settingsService) => settingsService.load(),
    },
  });
  $stateProvider.state('admin_dashboard.freshmen', {
    url: '/a/dashboard/freshmen',
    controller: 'FreshmenController as vm',
    template: require('./templates/freshmen_list.html'),
    resolve: {
      faculties: (facultyService) => facultyService.load(),
      settings: (settingsService) => settingsService.load(),
    },
  });
  $stateProvider.state('admin_dashboard.search', {
    url: '/a/dashboard/search',
    controller: 'SearchController as vm',
    template: require('./templates/search.html'),
    resolve: {
      faculties: (facultyService) => facultyService.load(),
      settings: (settingsService) => settingsService.load(),
    },
  });
  // Other routes
  $stateProvider.state('admin_dashboard_invite', {
    url: '/a/dashboard/invite',
    controller: 'InviteController as vm',
    template: require('./templates/invite.html'),
    resolve: {
      user: authenticate,
    },
  });
  $stateProvider.state('admin_dashboard_view_application', {
    url: '/a/dashboard/view/:id',
    controller: 'ApplicationViewController as vm',
    template: require('./templates/application.html'),
    resolve: {
      user: authenticate,
    },
  });
});

module.exports = app.name;
