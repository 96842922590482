import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { find, map } from 'lodash';

export default function ButtonDropdown(props) {
  const mapToText = props.mapToText
    || ((x, i) => String(x));
  const mapToValue = props.mapToValue
    || ((x, i) => i);
  const mapToKey = props.mapToKey
    || mapToValue;
  const mapToDescription = props.mapToDescription
    || ((x, i) => null);
  const activeItem = find(props.items, (x, i) => {
    return mapToValue(x, i) === props.value;
  });
  const icon = props.icon || 'filter';
  return <Dropdown
    floating labeled button icon={icon} className="icon"
    text={activeItem ? mapToText(activeItem) : props.text}>
    <Dropdown.Menu>
      {/*
      <Dropdown.Header icon={icon} content={props.text} />
      <Dropdown.Divider />
      */}
      {map(props.items, (x, i) => {
        return <Dropdown.Item
          key={mapToKey(x, i)}
          text={mapToText(x, i)}
          description={mapToDescription(x, i)}
          onClick={() => props.onChange(mapToValue(x, i))} />
      })}
    </Dropdown.Menu>
  </Dropdown>
}
