'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function () {

  class Entity {

    constructor(data) {
      _.assign(this, data);
    }

    toJSON() {
      const obj = {};
      _.each(this, (value, key) => {
        obj[key] = value;
      });
      return obj;
    }

  }

  return Entity;
}
