'use strict';

const _ = require('lodash');

module.exports =
/* @ngInject */
function ($state, $q, user, settings, faculties, applicationService, t) {

  const vm = this;

  vm.user = user;
  vm.settings = settings.getAll();
  vm.faculties = faculties;

  vm.reload = function () {
    const criteria = {};
    if (user.isSuperintendent()) {
      criteria.campus = user.toJSON().scope.campus;
    }

    $q
      .all([
        applicationService.getQueueStatistics(),
        applicationService.getQueueSize(criteria),
      ])
      .then(([queue_total, queue_filtered]) => {
        vm.queue_total = queue_total;
        vm.queue_filtered = queue_filtered;
        // Prepare statistics object for UiBarChart
        vm.stat = {
          // Stats for faculties
          faculties: _.map(vm.faculties, (faculty) => {
            return {
              name: faculty.abbr,
              value: queue_total.faculties[faculty.id] || 0,
              tooltip: faculty.getName(),
            };
          }),
        };
      });

    applicationService.find({ dashboard: true })
      .then((items) => {
        vm.applications = items;
      });

    applicationService.getActionLog()
      .then((items) => {
        vm.log = items;
      });
  };

  vm.reload();

  vm.view = function (item) {
    $state.go('admin_dashboard_view_application', { id: item.id });
  };

  vm.settle = function (item) {
    const confirmed = window.confirm(t('admin.dashboard.settle_dialog'));
    if (!confirmed) {
      return false;
    }
    applicationService.settle(item)
      .then(() => vm.reload());
  };

  vm.void = function (item) {
    const confirmed = window.confirm(t('admin.dashboard.void_dialog'));
    if (!confirmed) {
      return false;
    }
    applicationService.void(item)
      .then(() => vm.reload());
  };

}
