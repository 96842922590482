'use strict';

module.exports =
/* @ngInject */
function ($scope, $state, $stateParams, auth, error, t) {

  const vm = this;

  const ALLOWED_PATHS = ['application.list', 'admin_dashboard.index'];

  if ($stateParams.to === 'application.list') {
    vm.type = 'application';
  } else {
    vm.type = 'normal';
  }

  vm.user = {};
  vm.emailStatus = null;
  vm.error = null;
  vm.success = null;

  function beforeRequest() {
    // Reset errors
    vm.error = null;
    // Enable loader
    vm.loading = true;
  }

  function afterRequest() {
    // Disable loader
    vm.loading = false;
  }

  // Redirect if we are logged in
  auth.getUser().then((user) => {
    const redirectTo = vm.redirectTo = $stateParams.to;
    if (!ALLOWED_PATHS.includes(redirectTo)) {
      if (user.isAdministrativeUser()) {
        $state.go('admin_dashboard.index', {}, { location: 'replace' });
        return null;
      }
      if (user.isNormalUser()) {
        $state.go('application.list', {}, { location: 'replace' });
        return null;
      }
    }
    $state.go(redirectTo, {}, { location: 'replace' });
  });

  // Restore initial state when pressing escape
  $scope.$on('keypress_esc', () => {
    vm.emailStatus = null;
    $scope.$applyAsync();
  });

  vm.check = function (email) {
    beforeRequest();
    auth.check(email)
      .then((data) => {
        if (vm.type === 'application') {
          if (data.status === 'available') {
            vm.error = t('error.auth.email_not_found');
            return null;
          }
          if (data.status === 'registered' && !data.password) {
            // Send a login token and show a corresponding page
            return auth.sendApplicationInfo(email)
              .then(() => $state.go('auth_login_token_sent'));
          }
          // If this is a user checking application info, don't tell him
          // this is a passworded account
          vm.error = t('error.auth.email_not_found');
          return null;
        }
        if (vm.type === 'normal') {
          if (data.status === 'available') {
            vm.error = t('error.auth.username_not_found');
            return null;
          }
          vm.emailStatus = data.status;
        }
      })
      .catch(error.handler(vm))
      .finally(() => afterRequest());
  };

  vm.login = function (user) {
    beforeRequest();
    auth.login(user.username, user.password)
      .then((res) => {
        window.location.reload();
      })
      .catch(error.handler(vm))
      .finally(() => afterRequest());
  };

  // vm.resetPassword = function (email, password) {
  //   beforeRequest();
  //   auth.resetPassword($stateParams.code, email, password)
  //     .then(() => {
  //       // TODO: Show success message
  //       return auth.login(email, password)
  //     })
  //     .catch(error.handler(vm, t('auth.password_reset.error_message')))
  //     .finally(() => afterRequest());
  // };

}
